$base-px: 16px !default;
$c-white: #ffffff !default;
$c-black: #000000 !default;
$c-background: #FFFFFF !default;

$c-white-background: #FFFFFF !default;
$c-grey-blue-background: #84BFCC !default;
$c-sage-background: #7F7661 !default;
$c-mustard-background: #936A3E !default;
$c-rust-background: #80493E !default;
$c-grey-background: #E5E5E5 !default;

$c-button--disabled: #8d8d8d !default;

$f-title-stack: 'Cardo, serif' !default;

@mixin featured2_post_band_content($background-color, $theme: 'normal') {
    $text-color: null;

    @if $theme == 'light' {
        $text-color: $c-white;
    }

    background-color: $background-color;

    @if $text-color {
        & .featured-posts__subtitle,
        & .featured-posts__title,
        & .featured-posts__caption,
        & a {
            color: $text-color!important;
        }
    }
}

.block--featured2-posts {

    .band--grey {
        @include featured2_post_band_content($c-grey-background);
    }

    .band--l-grey {
        @include featured2_post_band_content($c-grey-background);

        @include breakpoint(tablet) {
            @include featured2_post_band_content(transparent);
        }
    }

    .band--white {
        @include featured2_post_band_content($c-white-background);
    }

    .band--grey-blue {
        @include featured2_post_band_content($c-grey-blue-background, 'light');
    }

    .band--sage {
        @include featured2_post_band_content($c-sage-background, 'light');
    }

    .band--mustard {
        @include featured2_post_band_content($c-mustard-background, 'light');
    }

    .band--rust {
        @include featured2_post_band_content($c-rust-background, 'light');
    }

    .featured-posts__container {
        text-align: center;
        padding: 70px $base-px;
        @include breakpoint(phablet) {
            padding: 50px $base-px 0 $base-px;
        }
    }
    
        .featured-posts__subtitle {
            @include font(18px, 24px);
            font-weight: 500;
            letter-spacing: 2px;
            color: $c-black;
            margin-bottom: 5px;
        }

        .featured-posts__title {
            font-family: $f-title-stack;
            @include font(62px, 90px);
            @include breakpoint(phablet) {
                @include font(32px, 90px);
            }
            font-weight: 500;
            margin-bottom: 10px;
        }

        .featured-posts__images {
            display: flex;
            justify-content: center;
            gap: $base-px;
            @include breakpoint(phablet) {
                flex-direction: column;
            }
        }

        .featured-posts__image-wrapper {
            text-align: left;
            max-width: 330px;
            @include breakpoint(phablet) {
                max-width: 100%;
                padding-bottom: 30px;
            }

            a {
                text-decoration: none;
                color: $c-black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .block__image img {
            width: 100%;
            max-width: 330px;
            height: auto;
            object-fit: cover;
            @include breakpoint(phablet) {
                max-width: 100%;
            }
        }

        .featured-posts__caption {
            @include font(16px, 20px);
            margin-top: 10px;
            max-width: 400px;
        }
}
