.block__panel {
    padding: 0 $site-gutter;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter--mobile;
    }

    .block__interior-bookings-iframe{
        margin: 25px auto;
    }
}

