@use "sass:math";

.block--vimeo-video,
.block--editor-vimeo-video,
.block--editor-you-tube-video {

    .band > div {
        position: relative;
    }

    .block__video {
        @include aspect(math.div(16,9));
        position: relative;

        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__video--mobile + .block__video {
        @include breakpoint(phablet) {
            display: none;
        }
    }

    .block__video--mobile {
        display: none;
        @include breakpoint(phablet) {
            display: block;
            @include aspect(math.div(2,3));

            // fixes some weird margin issue with vimeo
            margin-left: -2px;
            margin-right: -2px;
        }
    }

    .block__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: $c-white;

        svg {
            display: block;
        }

        span {
            margin-top: 8px;
            display: block;
            text-align: center;

            @include breakpoint(phablet) {
                margin-top: 3px;
            }
        }
    }

    .block__video-preview {
        width: 100%;
        height: auto;
    }

    .block__video-preview,
    .block__video-content {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .block__video-content {
        top: auto;
        bottom: 50px;
        z-index: 10;
        left: 24px;
        color: $c-white;

        @include breakpoint(phablet) {
            position: static;
            bottom: 0;
            margin: 15px $site-gutter;
            color: inherit;
        }
    }

    .wrap .block__video-content {
        left: 24px + $site-gutter;

        @include breakpoint(phablet) {
            margin: 15px 0;
        }
    }

    .block__title {
        @include page-intro-title;
        @include lightondark();
        @include margin(bottom, 10px);
    }

    .block__intro {
        @include h5;
        @include margin(bottom, 20px);

        max-width: 500px;
    }

    .video--text-centred .block__video-content {
        top: 50%;
        left: 50%;
        bottom: unset;
        transform: translate(-50%, -160%);
        text-align: center;

        .block__intro {
            margin-left: auto;
            margin-right: auto;
        }

        @include breakpoint(phablet) {
            transform: unset;
            text-align: unset;
            max-width: unset;
        }
    }

    .video--text-left-centred .block__video-content {
        top: 50%;
        bottom: unset;
        transform: translate(0%, -50%);
        max-width: 50%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
        }
    }

    .video--text-right-centred .block__video-content {
        top: 50%;
        left: unset;
        right: 24px + $site-gutter;
        bottom: unset;
        max-width: 50%;
        transform: translate(0%, -50%);

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
        }
    }

    .video--text-centred-bottom .block__video-content {
        top: unset;
        left: 50%;
        bottom: 50px;
        transform: translate(-50%, 0%);
        text-align: center;
        max-width: 75%;
        .block__intro {
            max-width: unset;
        }

        @include breakpoint(tablet) {
            max-width: 100%;
            left: unset;
            transform: translate(0%, 0%);
        }

        @include breakpoint(phablet) {
            transform: unset;
            text-align: unset;
            max-width: unset;
        }
    }

    .video--text-left-bottom .block__video-content {
        bottom: 50px;
        left: 24px + $site-gutter;
        max-width: 50%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
        }
    }

    .video--text-right-bottom .block__video-content {
        left: unset;
        right: 24px + $site-gutter;
        bottom: 50px;
        max-width: 50%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
        }
    }
}
