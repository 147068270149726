.block--quote,
.block--editor-quote {

    &.block--center {
        text-align: center;
    }

    &.block--right {
        text-align: right;
    }

    &.block--center {
        .block__title,
        blockquote {
            margin-right: auto;
            margin-left: auto;
        }
    }

    &.block--right {
        .block__title,
        blockquote {
            margin-left: auto;
        }
    }

    .block__title {
        @include font(24px, 32px);

        margin-bottom: 18px;

        @include breakpoint(phablet) {
            @include font(16px, 24px);
        }
    }

    .block__title,
    blockquote {
        width: 75%;

        .block--center &,
        .block--right & {
            margin-right: auto;
            margin-left: auto;
        }

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

        .block__source {
            @include font(16px, 24px);

            @include breakpoint(tablet) {
                @include font(14px, 22px);
            }
        }

        .block__quote {
            @include apply-map($f-title);
            @include font(38px, 48px);

            margin-bottom: 24px;

            @include breakpoint(tablet) {
                @include font(26px, 32px);

                margin-bottom: 18px;
            }
        }
}

.block--quote {
    margin-left: 25px;
    margin-right: 25px;

    @include breakpoint(phablet) {
        margin-left: $site-gutter--mobile;
        margin-right: $site-gutter--mobile;
    }

    .block__quote {
        quotes: "“" "”";

        &:before {
            content: open-quote;
        }

        &:after {
            content: close-quote;
        }
    }

    .block__source {
        display: inline-block;
        margin-bottom: 25px;
    }
}
