.block--twin-images,
.block--editor-twin-images {

    .block__title {
        @include h1;

        margin-bottom: 20px;

        @include breakpoint(phablet) {
            margin-bottom: 10px;
        }
    }


    .block__intro {
        @include page-intro-subtitle;
        @include margin(bottom, 20px);

        max-width: 795px;
    }

    .block__image {
        @include margin(bottom, 15px);

        @include breakpoint(tablet) {
            margin-right: -$site-gutter--mobile;
            margin-left: -$site-gutter--mobile;
        }

        @include breakpoint(phablet) {
            @include margin(bottom, 10px);
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__text {
        @extend .richtext;
        @extend .richtext--large;
        @extend .richtext--flush;
    }
}

.block--twin-images {
    padding-right: $site-gutter;
    padding-left: $site-gutter;

    @include breakpoint(tablet) {
        padding-right: 0;
        padding-left: 0;
    }

    .block__image {
        @include breakpoint(tablet) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
