$delivery_returns_block_title_font_size: 30px !default;
$delivery_returns_block_title_line_height: 38px !default;
$delivery_returns_block_title_mobile_font_size: 24px !default;
$delivery_returns_block_title_mobile_line_height: 34px !default;

.block--delivery-returns {
    margin-bottom: 40px;

    &:first-of-type {
        margin-top: 40px;
    }

    .block__title {
        @include font($delivery_returns_block_title_font_size, $delivery_returns_block_title_line_height);

        @include breakpoint(phablet) {
            @include font($delivery_returns_block_title_mobile_font_size, $delivery_returns_block_title_mobile_line_height);
        }
    }
}
