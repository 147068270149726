@use "sass:math";

$base-px: 16px !default;
$c-black: #000000 !default;
$c-white: #ffffff !default;

$c-white-background: #FFFFFF !default;
$c-grey-blue-background: #47535A !default;
$c-sage-background: #7E7760 !default;
$c-mustard-background: #93693D !default;
$c-rust-background: #80493D !default;
$c-grey-background: #E5E5E5 !default;

$c-button--disabled: #8d8d8d !default;

$f-title-stack: 'Cardo, serif' !default;

@mixin featured_post_band_content($background-color, $theme: 'normal') {
    $text-color: null;
    $button-extend: null;
    $button-border-extend: null;

    @if $theme == 'light' {
        $text-color: $c-white;
        $button-extend: '.button--white-hover';
        $button-border-extend: '.button--border-white';
    }

    background-color: $background-color;

    @if $text-color {
        & .block__title,
        & .block__text * {
            color: $text-color;
        }

        & .block__category {
            color: $text-color;
            border: solid 1px $text-color;
        }
    }

    @if $button-extend {
        & .button {
            @extend #{$button-extend};
        }
    }

    @if $button-border-extend {
        & .button--border {
            @extend #{$button-border-extend};
        }
    }
}

.button--border-white {
    border: 1px solid $c-white;

    [data-background="dark"] & {
        border-color: $c-black;
    }

    &[disabled],
    &.button--disabled {
        border-color: $c-button--disabled;
    }

    a:focus:not(:hover) &,
    &:focus:not(:hover),
    &.button--focus:not(:hover),
    input:focus:not(:hover) + &.button--radio {
        border-color: transparent;
    }
}

.button--white-hover {
    color: $c-white;
    background: inherit;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        color: $c-black;
        background-color: $c-white;
    }

    &[disabled],
    &.button--disabled {
        color: $c-white;
        background-color: $c-button--disabled;

        [data-background="dark"] & {
            color: $c-button--disabled;
            background-color: $c-black;
        }
    }
}

.block--featured-post {

    .band--grey {
        @include featured_post_band_content($c-grey-background);
    }

    .band--l-grey {
        @include featured_post_band_content($c-grey-background);

        @include breakpoint(tablet) {
            @include featured_post_band_content(transparent);
        }
    }

    .band--white {
        @include featured_post_band_content($c-white-background);
    }

    .band--grey-blue {
        @include featured_post_band_content($c-grey-blue-background, 'light');
    }

    .band--sage {
        @include featured_post_band_content($c-sage-background, 'light');
    }

    .band--mustard {
        @include featured_post_band_content($c-mustard-background, 'light');
    }

    .band--rust {
        @include featured_post_band_content($c-rust-background, 'light');
    }

    .block__left {
        flex: 0 0 auto;
    }

    .block__image {
        @include aspect(1);

        img {
            width: 100%;
            height: auto;
        }
    }

    .grid--reverse .block__right {
        text-align: right;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(tablet) {
            display: block;
        }
    }

        .block__panel {
            width: 100%;
            max-width: 620px + ($site-gutter * 2);
            padding: 64px $site-gutter $site-gutter;
            text-align: left;
            display: inline-block;

            @include breakpoint(tablet) {
                display: block;
                margin: 0 auto;
                padding: 30px $site-gutter--mobile;
            }

            @include breakpoint(phablet) {
                padding-top: $site-gutter--mobile;
                padding-bottom: 50px;
            }
        }

            .block__title {
                font-family: $f-title-stack;
                @include font(48px, 50px);

                max-width: 620px;

                @include breakpoint(tablet) {
                    max-width: none;
                }

                @include breakpoint(phablet) {
                    @include font(30px, 38px);
                }

                @include margin(bottom, 10px);

                a {
                    color: inherit;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .block__text {
                @extend .richtext;
                @extend .richtext--flush;
                @include margin(bottom, 20px);
            }

            .block__category {
                display: inline-block;
                border: solid 1px $c-black;
                border-radius: 10px;
                padding: 6px $base-px;
                color: $c-black;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                margin-bottom: 25px;

                &:hover {
                    text-decoration: underline;
                }
            }

    &.block--padded-compact {
        .block__image {
            @include aspect(4, 3);

            img {
                margin-top: -1px;
                margin-bottom: -1px;
            }
        }

        .block__right {
            text-align: left;
            display: flex;
            justify-content: flex-start;
        }

        .block__left {
            padding: 64px $site-gutter;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }

        .block__panel {
            max-width: calc($site-max-width / 2);
            align-content: center;

            @include breakpoint(tablet) {
                max-width: none;
                padding: 42px $site-gutter--mobile 60px $site-gutter--mobile;
            }
        }

        .grid--reverse {
            .block__right {
                justify-content: flex-end;
            }

            .block__panel {
                @include breakpoint(tablet) {
                    max-width: none;
                    padding-left: $site-gutter--mobile;
                }
            }
        }

        .block__title {
            font-family: $f-title-stack;
            @include font(60px, 72px);

            @include breakpoint(phablet) {
                @include font(42px, 46px);
            }

            @include margin(bottom, 20px);
        }
    }
}
