@use "sass:math";
@import '../../tools/mixins/_cover-image';
@import '../../tools/mixins/banner-gradient';

.block--banner {
    position: relative;

    a {
        pointer-events: all;
    }

    a:not(.button) {
        text-decoration: none;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    .inner-wrap {
        position: absolute;
        bottom: 50px;
        left: 0;
        color: $c-white;
        pointer-events: none;
    }

    .block__panel {
        max-width: 50%;
        margin: 0 auto;
        padding: 0;

        @include breakpoint(phablet) {
            max-width: unset;
            margin: unset;
        }
    }

    .block__image {
        @include aspect(math.div(5, 3));

        @include breakpoint(phablet) {
            @include aspect(1);
            @include margin(bottom, 25px);
        }

        img,
        picture {
            width: 100%;
            height: auto;
            max-width: none;
        }
    }

    &.banner--style-short .block__image {
        @include aspect(math.div(2, 1));
    }

    &.banner--style-extreme,
    &.banner--style-narrow-extreme {
        .block__image {
            @include aspect(math.div(7, 2));

            @include breakpoint(phablet) {
                @include aspect(math.div(4, 3));
            }
        }
    }

        .block__title {
            @include apply-map($f-title);
            @include font(60px, 72px);
            @include lightondark();
            @include margin(bottom, 24px);

            @include breakpoint(tablet) {
                @include font(42px, 46px);
            }
        }

        .block__intro {
            @include h5;
            @include margin(bottom, 24px);

            max-width: 500px;

            @include breakpoint(phablet) {
                max-width: unset;
            }
        }

        .block__button-wrap {
            @include margin(bottom, 24px);

            max-width: 500px;

            @include breakpoint(phablet) {
                max-width: unset;
                margin-left: unset;
                margin-right: unset;
                text-align: unset;
            }
        }

        .block__button {
            position: relative;
            z-index: 11;
            margin-right: 10px;

            &:hover,
            &:active {
                color: $c-white;
                background-color: $c-button--hover;
            }
        }

        .block__button.button--alt {
            &:hover,
            &:active {
                color: $c-black;
                background-color: $c-white;
            }
        }
}

.banner--gradient-light {
    position: relative;

    .banner--text-centred & picture {
        @include bannerGradient($c-white, $banner-gradient__text-position--c, 0.35);
    }

    .banner--text-left-centred & picture {
        @include bannerGradient($c-white, $banner-gradient__text-position--cl, 0.35);
    }

    .banner--text-left-bottom & picture {
        @include bannerGradient($c-white, $banner-gradient__text-position--bl, 0.35);
    }

    .banner--text-right-centred & picture {
        @include bannerGradient($c-white, $banner-gradient__text-position--cr, 0.35);
    }

    .banner--text-right-bottom & picture {
        @include bannerGradient($c-white, $banner-gradient__text-position--br, 0.35);
    }

    .banner--text-centred-bottom & picture {
        @include bannerGradient($c-white, $banner-gradient__text-position--bc, 0.35);
    }
}

.banner--gradient-dark {
    position: relative;

    .banner--text-centred & picture {
        @include bannerGradient($c-black, $banner-gradient__text-position--c);
    }

    .banner--text-left-centred & picture {
        @include bannerGradient($c-black, $banner-gradient__text-position--cl);
    }

    .banner--text-left-bottom & picture {
        @include bannerGradient($c-black, $banner-gradient__text-position--bl);
    }

    .banner--text-right-centred & picture {
        @include bannerGradient($c-black, $banner-gradient__text-position--cr);
    }

    .banner--text-right-bottom & picture {
        @include bannerGradient($c-black, $banner-gradient__text-position--br);
    }

    .banner--text-centred-bottom & picture {
        @include bannerGradient($c-black, $banner-gradient__text-position--bc);
    }
}

.block--banner .wrap {
    z-index: 10;
}

.banner--light-on-dark {
    color: $c-white;
    position: relative;
}

.banner--dark-on-light {
    color: $c-black;
    position: relative;
}

.banner--text-centred .inner-wrap {
    top: 50%;
    left: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);
    text-align: center;

    .block__intro {
        max-width: 37.5%;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(phablet) {
            max-width: unset;
        }
    }

    .block__button-wrap,
    .block__panel,
    .inner-wrap {
        max-width: unset;
    }
}

.banner--text-left-centred .inner-wrap {
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    display: inline-table;

    .block__panel {
        margin: unset;
        margin-right: auto;
    }
}

.banner--text-right-centred .inner-wrap {
    top: 50%;
    left: unset;
    right: 0;
    transform: translate(0%, -50%);
    display: inline-table;

    .block__title,
    .block__intro,
    .block__button-wrap {
        text-align: right;
    }

    .block__intro {
        margin-left: auto;
        margin-right: 0;
    }

    .block__button-wrap {
        margin-left: auto;
        margin-right: 0;

        @include breakpoint(phablet) {
            margin-left: unset;
            margin-right: unset;
        }
    }

    .block__panel {
        margin: unset;
        margin-left: auto;
    }
}

.banner--text-centred-bottom .inner-wrap {
    top: unset;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 0%);
    text-align: center;

    .block__intro {
        max-width: 37.5%;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(phablet) {
            max-width: none;
        }
    }

    .block__button-wrap,
    .block__panel,
    .inner-wrap {
        max-width: unset;
    }
}

.banner--text-left-bottom .inner-wrap {
    bottom: 50px;
    left: 0;

    .block__panel {
        margin: unset;
        margin-right: auto;
    }
}

.block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap {
    @include breakpoint(phablet) {
        position: static;
        bottom: 0;
        margin-bottom: 48px;
        text-align: unset;
        transform: unset;
    }

    .block__title,
    .block__intro,
    .block__button-wrap {
        @include breakpoint(phablet) {
            text-align: unset;
        }
    }

    .block__panel {
        @include breakpoint(phablet) {
            margin-left: 0;
        }
    }

    .block__button {
        @include breakpoint(phablet) {
            color: $c-white;
            background-color: $c-black;
            border: 1px solid $c-black;

            &:hover,
            &:active {
                color: $c-black;
                background-color: $c-white;
            }
        }
    }

    .banner--light-on-dark {
        @include breakpoint(phablet) {
            color: $c-black !important;
        }
    }

    .block__button.button--transparent {
        @include breakpoint(phablet) {
            background: transparent;
            color: $c-black;

            &:hover,
            &:active {
                color: $c-white;
                background-color: $c-black;
            }

            &.btn-alt {
                color: $c-white;

                &:hover,
                &:active {
                    color: $c-black;
                    background-color: $c-white;
                }
            }
        }
    }
}

.banner--text-right-bottom .inner-wrap {
    left: unset;
    right: 0;

    .block__title,
    .block__intro,
    .block__button-wrap {
        text-align: right;
    }

    .block__intro {
        margin-left: auto;
        margin-right: 0;
    }

    .block__button-wrap {
        margin-left: auto;
        margin-right: 0;

        @include breakpoint(phablet) {
            margin-left: unset;
            margin-right: unset;
        }
    }

    .block__panel {
        margin: unset;
        margin-left: auto;
    }
}

.banner--style-extreme,
.banner--style-narrow-extreme {
    .block__panel .block__title {
        @include font(38px, 48px);

        @include breakpoint(tablet) {
            @include font(26px, 32px);
        }
    }
}

.banner--style-narrow-extreme {
    .inner-wrap {
        padding: 0 calc($site-gutter * 2);

        @include breakpoint(tablet) {
            padding: 0 $site-gutter;
        }
    }
}
