@charset "UTF-8";
:root {
  --c-border: #000000;
  --c-panel-background: #FFFFFF;
  --c-disabled: #EEEEEE;
  --c-focus: #000000;
  --c-field-disabled: var(--c-disabled);
  --c-field-focus: var(--c-focus);
  --c-input-background: var(--c-panel-background);
  --c-input-border: var(--c-border);
}

@media screen and (min-width: 679px) {
  .section-blocks:last-child .block:last-child .band {
    margin-bottom: 0;
  }
}

/* ======================= */
/* = Richtext stylesheet = */
/* ======================= */
/* This should only apply to the wysiwyg */
body.richtext, .block--featured-post body.block__text, .block--editor-newsletter-signup body.block__text, .block--form body.form__message,
.block--editor-form body.form__message, .block--editor-splash body.block__text, .block--quad-callouts body.block__text,
.block--editor-quad-callouts body.block__text, .block--editor-triple-callouts body.block__text,
.block--triple-callouts body.block__text, .block--editor-twin-callouts body.block__text, .block--twin-images body.block__text,
.block--editor-twin-images body.block__text, .block--image body.block__text,
.block--editor-single-image body.block__text, .block--richtext .block__panel--stylised body.block__text,
.block--editor-richtext .block__panel--stylised body.block__text,
.block--editor-rich-text-with-cta .block__panel--stylised body.block__text, .block--richtext body.block__text,
.block--editor-richtext body.block__text,
.block--editor-rich-text-with-cta body.block__text, .block--editor-heading body.block__text, .block--editor-feature body.block__text, .block--editor-feature body.block__intro {
  font-size: 100%;
  background: #FFF;
}

.richtext, .block--featured-post .block__text, .block--editor-newsletter-signup .block__text, .block--form .form__message,
.block--editor-form .form__message, .block--editor-splash .block__text, .block--quad-callouts .block__text,
.block--editor-quad-callouts .block__text, .block--editor-triple-callouts .block__text,
.block--triple-callouts .block__text, .block--editor-twin-callouts .block__text, .block--twin-images .block__text,
.block--editor-twin-images .block__text, .block--image .block__text,
.block--editor-single-image .block__text, .block--richtext .block__panel--stylised .block__text,
.block--editor-richtext .block__panel--stylised .block__text,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text, .block--richtext .block__text,
.block--editor-richtext .block__text,
.block--editor-rich-text-with-cta .block__text, .block--editor-heading .block__text, .block--editor-feature .block__text, .block--editor-feature .block__intro {
  font-family: "HK Grotesk", Arial, Helvetica, sans-serif;
  line-height: 1.7;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  color: #000000;
}
.richtext h1, .block--featured-post .block__text h1, .block--editor-newsletter-signup .block__text h1, .block--form .form__message h1,
.block--editor-form .form__message h1, .block--editor-splash .block__text h1, .block--quad-callouts .block__text h1,
.block--editor-quad-callouts .block__text h1, .block--editor-triple-callouts .block__text h1,
.block--triple-callouts .block__text h1, .block--editor-twin-callouts .block__text h1, .block--twin-images .block__text h1,
.block--editor-twin-images .block__text h1, .block--image .block__text h1,
.block--editor-single-image .block__text h1, .block--richtext .block__panel--stylised .block__text h1,
.block--editor-richtext .block__panel--stylised .block__text h1,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h1, .block--richtext .block__text h1,
.block--editor-richtext .block__text h1,
.block--editor-rich-text-with-cta .block__text h1, .block--editor-heading .block__text h1, .block--editor-feature .block__text h1, .block--editor-feature .block__intro h1 {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
}
@media screen and (max-width: 42.5em) {
  .richtext h1, .block--featured-post .block__text h1, .block--editor-newsletter-signup .block__text h1, .block--form .form__message h1,
  .block--editor-form .form__message h1, .block--editor-splash .block__text h1, .block--quad-callouts .block__text h1,
  .block--editor-quad-callouts .block__text h1, .block--editor-triple-callouts .block__text h1,
  .block--triple-callouts .block__text h1, .block--editor-twin-callouts .block__text h1, .block--twin-images .block__text h1,
  .block--editor-twin-images .block__text h1, .block--image .block__text h1,
  .block--editor-single-image .block__text h1, .block--richtext .block__panel--stylised .block__text h1,
  .block--editor-richtext .block__panel--stylised .block__text h1,
  .block--editor-rich-text-with-cta .block__panel--stylised .block__text h1, .block--richtext .block__text h1,
  .block--editor-richtext .block__text h1,
  .block--editor-rich-text-with-cta .block__text h1, .block--editor-heading .block__text h1, .block--editor-feature .block__text h1, .block--editor-feature .block__intro h1 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2666666667;
  }
}
.richtext h2, .block--featured-post .block__text h2, .block--editor-newsletter-signup .block__text h2, .block--form .form__message h2,
.block--editor-form .form__message h2, .block--editor-splash .block__text h2, .block--quad-callouts .block__text h2,
.block--editor-quad-callouts .block__text h2, .block--editor-triple-callouts .block__text h2,
.block--triple-callouts .block__text h2, .block--editor-twin-callouts .block__text h2, .block--twin-images .block__text h2,
.block--editor-twin-images .block__text h2, .block--image .block__text h2,
.block--editor-single-image .block__text h2, .block--richtext .block__panel--stylised .block__text h2,
.block--editor-richtext .block__panel--stylised .block__text h2,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h2, .block--richtext .block__text h2,
.block--editor-richtext .block__text h2,
.block--editor-rich-text-with-cta .block__text h2, .block--editor-heading .block__text h2, .block--editor-feature .block__text h2, .block--editor-feature .block__intro h2 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
}
.richtext h3, .block--featured-post .block__text h3, .block--editor-newsletter-signup .block__text h3, .block--form .form__message h3,
.block--editor-form .form__message h3, .block--editor-splash .block__text h3, .block--quad-callouts .block__text h3,
.block--editor-quad-callouts .block__text h3, .block--editor-triple-callouts .block__text h3,
.block--triple-callouts .block__text h3, .block--editor-twin-callouts .block__text h3, .block--twin-images .block__text h3,
.block--editor-twin-images .block__text h3, .block--image .block__text h3,
.block--editor-single-image .block__text h3, .block--richtext .block__panel--stylised .block__text h3,
.block--editor-richtext .block__panel--stylised .block__text h3,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h3, .block--richtext .block__text h3,
.block--editor-richtext .block__text h3,
.block--editor-rich-text-with-cta .block__text h3, .block--editor-heading .block__text h3, .block--editor-feature .block__text h3, .block--editor-feature .block__intro h3 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.1666666667;
}
.richtext h4, .block--featured-post .block__text h4, .block--editor-newsletter-signup .block__text h4, .block--form .form__message h4,
.block--editor-form .form__message h4, .block--editor-splash .block__text h4, .block--quad-callouts .block__text h4,
.block--editor-quad-callouts .block__text h4, .block--editor-triple-callouts .block__text h4,
.block--triple-callouts .block__text h4, .block--editor-twin-callouts .block__text h4, .block--twin-images .block__text h4,
.block--editor-twin-images .block__text h4, .block--image .block__text h4,
.block--editor-single-image .block__text h4, .block--richtext .block__panel--stylised .block__text h4,
.block--editor-richtext .block__panel--stylised .block__text h4,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h4, .block--richtext .block__text h4,
.block--editor-richtext .block__text h4,
.block--editor-rich-text-with-cta .block__text h4, .block--editor-heading .block__text h4, .block--editor-feature .block__text h4, .block--editor-feature .block__intro h4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.richtext h5, .block--featured-post .block__text h5, .block--editor-newsletter-signup .block__text h5, .block--form .form__message h5,
.block--editor-form .form__message h5, .block--editor-splash .block__text h5, .block--quad-callouts .block__text h5,
.block--editor-quad-callouts .block__text h5, .block--editor-triple-callouts .block__text h5,
.block--triple-callouts .block__text h5, .block--editor-twin-callouts .block__text h5, .block--twin-images .block__text h5,
.block--editor-twin-images .block__text h5, .block--image .block__text h5,
.block--editor-single-image .block__text h5, .block--richtext .block__panel--stylised .block__text h5,
.block--editor-richtext .block__panel--stylised .block__text h5,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h5, .block--richtext .block__text h5,
.block--editor-richtext .block__text h5,
.block--editor-rich-text-with-cta .block__text h5, .block--editor-heading .block__text h5, .block--editor-feature .block__text h5, .block--editor-feature .block__intro h5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
}
.richtext h6, .block--featured-post .block__text h6, .block--editor-newsletter-signup .block__text h6, .block--form .form__message h6,
.block--editor-form .form__message h6, .block--editor-splash .block__text h6, .block--quad-callouts .block__text h6,
.block--editor-quad-callouts .block__text h6, .block--editor-triple-callouts .block__text h6,
.block--triple-callouts .block__text h6, .block--editor-twin-callouts .block__text h6, .block--twin-images .block__text h6,
.block--editor-twin-images .block__text h6, .block--image .block__text h6,
.block--editor-single-image .block__text h6, .block--richtext .block__panel--stylised .block__text h6,
.block--editor-richtext .block__panel--stylised .block__text h6,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h6, .block--richtext .block__text h6,
.block--editor-richtext .block__text h6,
.block--editor-rich-text-with-cta .block__text h6, .block--editor-heading .block__text h6, .block--editor-feature .block__text h6, .block--editor-feature .block__intro h6 {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
.richtext h1, .block--featured-post .block__text h1, .block--editor-newsletter-signup .block__text h1, .block--form .form__message h1,
.block--editor-form .form__message h1, .block--editor-splash .block__text h1, .block--quad-callouts .block__text h1,
.block--editor-quad-callouts .block__text h1, .block--editor-triple-callouts .block__text h1,
.block--triple-callouts .block__text h1, .block--editor-twin-callouts .block__text h1, .block--twin-images .block__text h1,
.block--editor-twin-images .block__text h1, .block--image .block__text h1,
.block--editor-single-image .block__text h1, .block--richtext .block__panel--stylised .block__text h1,
.block--editor-richtext .block__panel--stylised .block__text h1,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h1, .block--richtext .block__text h1,
.block--editor-richtext .block__text h1,
.block--editor-rich-text-with-cta .block__text h1, .block--editor-heading .block__text h1, .block--editor-feature .block__text h1, .block--editor-feature .block__intro h1, .richtext h2, .block--featured-post .block__text h2, .block--editor-newsletter-signup .block__text h2, .block--form .form__message h2,
.block--editor-form .form__message h2, .block--editor-splash .block__text h2, .block--quad-callouts .block__text h2,
.block--editor-quad-callouts .block__text h2, .block--editor-triple-callouts .block__text h2,
.block--triple-callouts .block__text h2, .block--editor-twin-callouts .block__text h2, .block--twin-images .block__text h2,
.block--editor-twin-images .block__text h2, .block--image .block__text h2,
.block--editor-single-image .block__text h2, .block--richtext .block__panel--stylised .block__text h2,
.block--editor-richtext .block__panel--stylised .block__text h2,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h2, .block--richtext .block__text h2,
.block--editor-richtext .block__text h2,
.block--editor-rich-text-with-cta .block__text h2, .block--editor-heading .block__text h2, .block--editor-feature .block__text h2, .block--editor-feature .block__intro h2, .richtext h3, .block--featured-post .block__text h3, .block--editor-newsletter-signup .block__text h3, .block--form .form__message h3,
.block--editor-form .form__message h3, .block--editor-splash .block__text h3, .block--quad-callouts .block__text h3,
.block--editor-quad-callouts .block__text h3, .block--editor-triple-callouts .block__text h3,
.block--triple-callouts .block__text h3, .block--editor-twin-callouts .block__text h3, .block--twin-images .block__text h3,
.block--editor-twin-images .block__text h3, .block--image .block__text h3,
.block--editor-single-image .block__text h3, .block--richtext .block__panel--stylised .block__text h3,
.block--editor-richtext .block__panel--stylised .block__text h3,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h3, .block--richtext .block__text h3,
.block--editor-richtext .block__text h3,
.block--editor-rich-text-with-cta .block__text h3, .block--editor-heading .block__text h3, .block--editor-feature .block__text h3, .block--editor-feature .block__intro h3, .richtext h4, .block--featured-post .block__text h4, .block--editor-newsletter-signup .block__text h4, .block--form .form__message h4,
.block--editor-form .form__message h4, .block--editor-splash .block__text h4, .block--quad-callouts .block__text h4,
.block--editor-quad-callouts .block__text h4, .block--editor-triple-callouts .block__text h4,
.block--triple-callouts .block__text h4, .block--editor-twin-callouts .block__text h4, .block--twin-images .block__text h4,
.block--editor-twin-images .block__text h4, .block--image .block__text h4,
.block--editor-single-image .block__text h4, .block--richtext .block__panel--stylised .block__text h4,
.block--editor-richtext .block__panel--stylised .block__text h4,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h4, .block--richtext .block__text h4,
.block--editor-richtext .block__text h4,
.block--editor-rich-text-with-cta .block__text h4, .block--editor-heading .block__text h4, .block--editor-feature .block__text h4, .block--editor-feature .block__intro h4, .richtext h5, .block--featured-post .block__text h5, .block--editor-newsletter-signup .block__text h5, .block--form .form__message h5,
.block--editor-form .form__message h5, .block--editor-splash .block__text h5, .block--quad-callouts .block__text h5,
.block--editor-quad-callouts .block__text h5, .block--editor-triple-callouts .block__text h5,
.block--triple-callouts .block__text h5, .block--editor-twin-callouts .block__text h5, .block--twin-images .block__text h5,
.block--editor-twin-images .block__text h5, .block--image .block__text h5,
.block--editor-single-image .block__text h5, .block--richtext .block__panel--stylised .block__text h5,
.block--editor-richtext .block__panel--stylised .block__text h5,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h5, .block--richtext .block__text h5,
.block--editor-richtext .block__text h5,
.block--editor-rich-text-with-cta .block__text h5, .block--editor-heading .block__text h5, .block--editor-feature .block__text h5, .block--editor-feature .block__intro h5, .richtext h6, .block--featured-post .block__text h6, .block--editor-newsletter-signup .block__text h6, .block--form .form__message h6,
.block--editor-form .form__message h6, .block--editor-splash .block__text h6, .block--quad-callouts .block__text h6,
.block--editor-quad-callouts .block__text h6, .block--editor-triple-callouts .block__text h6,
.block--triple-callouts .block__text h6, .block--editor-twin-callouts .block__text h6, .block--twin-images .block__text h6,
.block--editor-twin-images .block__text h6, .block--image .block__text h6,
.block--editor-single-image .block__text h6, .block--richtext .block__panel--stylised .block__text h6,
.block--editor-richtext .block__panel--stylised .block__text h6,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text h6, .block--richtext .block__text h6,
.block--editor-richtext .block__text h6,
.block--editor-rich-text-with-cta .block__text h6, .block--editor-heading .block__text h6, .block--editor-feature .block__text h6, .block--editor-feature .block__intro h6 {
  margin-bottom: 0.5em;
}
.richtext p, .block--featured-post .block__text p, .block--editor-newsletter-signup .block__text p, .block--form .form__message p,
.block--editor-form .form__message p, .block--editor-splash .block__text p, .block--quad-callouts .block__text p,
.block--editor-quad-callouts .block__text p, .block--editor-triple-callouts .block__text p,
.block--triple-callouts .block__text p, .block--editor-twin-callouts .block__text p, .block--twin-images .block__text p,
.block--editor-twin-images .block__text p, .block--image .block__text p,
.block--editor-single-image .block__text p, .block--richtext .block__panel--stylised .block__text p,
.block--editor-richtext .block__panel--stylised .block__text p,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text p, .block--richtext .block__text p,
.block--editor-richtext .block__text p,
.block--editor-rich-text-with-cta .block__text p, .block--editor-heading .block__text p, .block--editor-feature .block__text p, .block--editor-feature .block__intro p {
  margin-bottom: 0.625em;
}
.richtext strong, .block--featured-post .block__text strong, .block--editor-newsletter-signup .block__text strong, .block--form .form__message strong,
.block--editor-form .form__message strong, .block--editor-splash .block__text strong, .block--quad-callouts .block__text strong,
.block--editor-quad-callouts .block__text strong, .block--editor-triple-callouts .block__text strong,
.block--triple-callouts .block__text strong, .block--editor-twin-callouts .block__text strong, .block--twin-images .block__text strong,
.block--editor-twin-images .block__text strong, .block--image .block__text strong,
.block--editor-single-image .block__text strong, .block--richtext .block__panel--stylised .block__text strong,
.block--editor-richtext .block__panel--stylised .block__text strong,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text strong, .block--richtext .block__text strong,
.block--editor-richtext .block__text strong,
.block--editor-rich-text-with-cta .block__text strong, .block--editor-heading .block__text strong, .block--editor-feature .block__text strong, .block--editor-feature .block__intro strong {
  font-weight: 700;
}
.richtext blockquote, .block--featured-post .block__text blockquote, .block--editor-newsletter-signup .block__text blockquote, .block--form .form__message blockquote,
.block--editor-form .form__message blockquote, .block--editor-splash .block__text blockquote, .block--quad-callouts .block__text blockquote,
.block--editor-quad-callouts .block__text blockquote, .block--editor-triple-callouts .block__text blockquote,
.block--triple-callouts .block__text blockquote, .block--editor-twin-callouts .block__text blockquote, .block--twin-images .block__text blockquote,
.block--editor-twin-images .block__text blockquote, .block--image .block__text blockquote,
.block--editor-single-image .block__text blockquote, .block--richtext .block__panel--stylised .block__text blockquote,
.block--editor-richtext .block__panel--stylised .block__text blockquote,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text blockquote, .block--richtext .block__text blockquote,
.block--editor-richtext .block__text blockquote,
.block--editor-rich-text-with-cta .block__text blockquote, .block--editor-heading .block__text blockquote, .block--editor-feature .block__text blockquote, .block--editor-feature .block__intro blockquote {
  border: 1px solid #BBB;
  background-color: #EEE;
  padding: 1em 2em;
  margin: 0.5em 0 1em 0;
  font-style: italic;
  font-family: Georgia, Times, serif;
  line-height: 1.4;
}
.richtext ul, .block--featured-post .block__text ul, .block--editor-newsletter-signup .block__text ul, .block--form .form__message ul,
.block--editor-form .form__message ul, .block--editor-splash .block__text ul, .block--quad-callouts .block__text ul,
.block--editor-quad-callouts .block__text ul, .block--editor-triple-callouts .block__text ul,
.block--triple-callouts .block__text ul, .block--editor-twin-callouts .block__text ul, .block--twin-images .block__text ul,
.block--editor-twin-images .block__text ul, .block--image .block__text ul,
.block--editor-single-image .block__text ul, .block--richtext .block__panel--stylised .block__text ul,
.block--editor-richtext .block__panel--stylised .block__text ul,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ul, .block--richtext .block__text ul,
.block--editor-richtext .block__text ul,
.block--editor-rich-text-with-cta .block__text ul, .block--editor-heading .block__text ul, .block--editor-feature .block__text ul, .block--editor-feature .block__intro ul,
.richtext ol,
.block--featured-post .block__text ol,
.block--editor-newsletter-signup .block__text ol,
.block--form .form__message ol,
.block--editor-form .form__message ol,
.block--editor-splash .block__text ol,
.block--quad-callouts .block__text ol,
.block--editor-quad-callouts .block__text ol,
.block--editor-triple-callouts .block__text ol,
.block--triple-callouts .block__text ol,
.block--editor-twin-callouts .block__text ol,
.block--twin-images .block__text ol,
.block--editor-twin-images .block__text ol,
.block--image .block__text ol,
.block--editor-single-image .block__text ol,
.block--richtext .block__panel--stylised .block__text ol,
.block--editor-richtext .block__panel--stylised .block__text ol,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ol,
.block--richtext .block__text ol,
.block--editor-richtext .block__text ol,
.block--editor-rich-text-with-cta .block__text ol,
.block--editor-heading .block__text ol,
.block--editor-feature .block__text ol,
.block--editor-feature .block__intro ol {
  padding: 0;
  margin: 0;
  margin-bottom: 1.875em;
}
.richtext ul ul, .block--featured-post .block__text ul ul, .block--editor-newsletter-signup .block__text ul ul, .block--form .form__message ul ul,
.block--editor-form .form__message ul ul, .block--editor-splash .block__text ul ul, .block--quad-callouts .block__text ul ul,
.block--editor-quad-callouts .block__text ul ul, .block--editor-triple-callouts .block__text ul ul,
.block--triple-callouts .block__text ul ul, .block--editor-twin-callouts .block__text ul ul, .block--twin-images .block__text ul ul,
.block--editor-twin-images .block__text ul ul, .block--image .block__text ul ul,
.block--editor-single-image .block__text ul ul, .block--richtext .block__panel--stylised .block__text ul ul,
.block--editor-richtext .block__panel--stylised .block__text ul ul,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ul ul, .block--richtext .block__text ul ul,
.block--editor-richtext .block__text ul ul,
.block--editor-rich-text-with-cta .block__text ul ul, .block--editor-heading .block__text ul ul, .block--editor-feature .block__text ul ul, .block--editor-feature .block__intro ul ul,
.richtext ol ol,
.block--featured-post .block__text ol ol,
.block--editor-newsletter-signup .block__text ol ol,
.block--form .form__message ol ol,
.block--editor-form .form__message ol ol,
.block--editor-splash .block__text ol ol,
.block--quad-callouts .block__text ol ol,
.block--editor-quad-callouts .block__text ol ol,
.block--editor-triple-callouts .block__text ol ol,
.block--triple-callouts .block__text ol ol,
.block--editor-twin-callouts .block__text ol ol,
.block--twin-images .block__text ol ol,
.block--editor-twin-images .block__text ol ol,
.block--image .block__text ol ol,
.block--editor-single-image .block__text ol ol,
.block--richtext .block__panel--stylised .block__text ol ol,
.block--editor-richtext .block__panel--stylised .block__text ol ol,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ol ol,
.block--richtext .block__text ol ol,
.block--editor-richtext .block__text ol ol,
.block--editor-rich-text-with-cta .block__text ol ol,
.block--editor-heading .block__text ol ol,
.block--editor-feature .block__text ol ol,
.block--editor-feature .block__intro ol ol {
  margin-top: 0.5em;
}
.richtext ul, .block--featured-post .block__text ul, .block--editor-newsletter-signup .block__text ul, .block--form .form__message ul,
.block--editor-form .form__message ul, .block--editor-splash .block__text ul, .block--quad-callouts .block__text ul,
.block--editor-quad-callouts .block__text ul, .block--editor-triple-callouts .block__text ul,
.block--triple-callouts .block__text ul, .block--editor-twin-callouts .block__text ul, .block--twin-images .block__text ul,
.block--editor-twin-images .block__text ul, .block--image .block__text ul,
.block--editor-single-image .block__text ul, .block--richtext .block__panel--stylised .block__text ul,
.block--editor-richtext .block__panel--stylised .block__text ul,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ul, .block--richtext .block__text ul,
.block--editor-richtext .block__text ul,
.block--editor-rich-text-with-cta .block__text ul, .block--editor-heading .block__text ul, .block--editor-feature .block__text ul, .block--editor-feature .block__intro ul {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.richtext ul li, .block--featured-post .block__text ul li, .block--editor-newsletter-signup .block__text ul li, .block--form .form__message ul li,
.block--editor-form .form__message ul li, .block--editor-splash .block__text ul li, .block--quad-callouts .block__text ul li,
.block--editor-quad-callouts .block__text ul li, .block--editor-triple-callouts .block__text ul li,
.block--triple-callouts .block__text ul li, .block--editor-twin-callouts .block__text ul li, .block--twin-images .block__text ul li,
.block--editor-twin-images .block__text ul li, .block--image .block__text ul li,
.block--editor-single-image .block__text ul li, .block--richtext .block__panel--stylised .block__text ul li,
.block--editor-richtext .block__panel--stylised .block__text ul li,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ul li, .block--richtext .block__text ul li,
.block--editor-richtext .block__text ul li,
.block--editor-rich-text-with-cta .block__text ul li, .block--editor-heading .block__text ul li, .block--editor-feature .block__text ul li, .block--editor-feature .block__intro ul li, .richtext ul a, .block--featured-post .block__text ul a, .block--editor-newsletter-signup .block__text ul a, .block--form .form__message ul a,
.block--editor-form .form__message ul a, .block--editor-splash .block__text ul a, .block--quad-callouts .block__text ul a,
.block--editor-quad-callouts .block__text ul a, .block--editor-triple-callouts .block__text ul a,
.block--triple-callouts .block__text ul a, .block--editor-twin-callouts .block__text ul a, .block--twin-images .block__text ul a,
.block--editor-twin-images .block__text ul a, .block--image .block__text ul a,
.block--editor-single-image .block__text ul a, .block--richtext .block__panel--stylised .block__text ul a,
.block--editor-richtext .block__panel--stylised .block__text ul a,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ul a, .block--richtext .block__text ul a,
.block--editor-richtext .block__text ul a,
.block--editor-rich-text-with-cta .block__text ul a, .block--editor-heading .block__text ul a, .block--editor-feature .block__text ul a, .block--editor-feature .block__intro ul a {
  margin: -4px 0;
}
.richtext ul li:before, .block--featured-post .block__text ul li:before, .block--editor-newsletter-signup .block__text ul li:before, .block--form .form__message ul li:before,
.block--editor-form .form__message ul li:before, .block--editor-splash .block__text ul li:before, .block--quad-callouts .block__text ul li:before,
.block--editor-quad-callouts .block__text ul li:before, .block--editor-triple-callouts .block__text ul li:before,
.block--triple-callouts .block__text ul li:before, .block--editor-twin-callouts .block__text ul li:before, .block--twin-images .block__text ul li:before,
.block--editor-twin-images .block__text ul li:before, .block--image .block__text ul li:before,
.block--editor-single-image .block__text ul li:before, .block--richtext .block__panel--stylised .block__text ul li:before,
.block--editor-richtext .block__panel--stylised .block__text ul li:before,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ul li:before, .block--richtext .block__text ul li:before,
.block--editor-richtext .block__text ul li:before,
.block--editor-rich-text-with-cta .block__text ul li:before, .block--editor-heading .block__text ul li:before, .block--editor-feature .block__text ul li:before, .block--editor-feature .block__intro ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #000000;
}
.richtext ol, .block--featured-post .block__text ol, .block--editor-newsletter-signup .block__text ol, .block--form .form__message ol,
.block--editor-form .form__message ol, .block--editor-splash .block__text ol, .block--quad-callouts .block__text ol,
.block--editor-quad-callouts .block__text ol, .block--editor-triple-callouts .block__text ol,
.block--triple-callouts .block__text ol, .block--editor-twin-callouts .block__text ol, .block--twin-images .block__text ol,
.block--editor-twin-images .block__text ol, .block--image .block__text ol,
.block--editor-single-image .block__text ol, .block--richtext .block__panel--stylised .block__text ol,
.block--editor-richtext .block__panel--stylised .block__text ol,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ol, .block--richtext .block__text ol,
.block--editor-richtext .block__text ol,
.block--editor-rich-text-with-cta .block__text ol, .block--editor-heading .block__text ol, .block--editor-feature .block__text ol, .block--editor-feature .block__intro ol {
  list-style: decimal outside;
  padding-left: 20px;
}
.richtext ol ol, .block--featured-post .block__text ol ol, .block--editor-newsletter-signup .block__text ol ol, .block--form .form__message ol ol,
.block--editor-form .form__message ol ol, .block--editor-splash .block__text ol ol, .block--quad-callouts .block__text ol ol,
.block--editor-quad-callouts .block__text ol ol, .block--editor-triple-callouts .block__text ol ol,
.block--triple-callouts .block__text ol ol, .block--editor-twin-callouts .block__text ol ol, .block--twin-images .block__text ol ol,
.block--editor-twin-images .block__text ol ol, .block--image .block__text ol ol,
.block--editor-single-image .block__text ol ol, .block--richtext .block__panel--stylised .block__text ol ol,
.block--editor-richtext .block__panel--stylised .block__text ol ol,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ol ol, .block--richtext .block__text ol ol,
.block--editor-richtext .block__text ol ol,
.block--editor-rich-text-with-cta .block__text ol ol, .block--editor-heading .block__text ol ol, .block--editor-feature .block__text ol ol, .block--editor-feature .block__intro ol ol {
  list-style-type: lower-alpha;
}
.richtext ol ol ol, .block--featured-post .block__text ol ol ol, .block--editor-newsletter-signup .block__text ol ol ol, .block--form .form__message ol ol ol,
.block--editor-form .form__message ol ol ol, .block--editor-splash .block__text ol ol ol, .block--quad-callouts .block__text ol ol ol,
.block--editor-quad-callouts .block__text ol ol ol, .block--editor-triple-callouts .block__text ol ol ol,
.block--triple-callouts .block__text ol ol ol, .block--editor-twin-callouts .block__text ol ol ol, .block--twin-images .block__text ol ol ol,
.block--editor-twin-images .block__text ol ol ol, .block--image .block__text ol ol ol,
.block--editor-single-image .block__text ol ol ol, .block--richtext .block__panel--stylised .block__text ol ol ol,
.block--editor-richtext .block__panel--stylised .block__text ol ol ol,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ol ol ol, .block--richtext .block__text ol ol ol,
.block--editor-richtext .block__text ol ol ol,
.block--editor-rich-text-with-cta .block__text ol ol ol, .block--editor-heading .block__text ol ol ol, .block--editor-feature .block__text ol ol ol, .block--editor-feature .block__intro ol ol ol {
  list-style-type: lower-roman;
}
.richtext li, .block--featured-post .block__text li, .block--editor-newsletter-signup .block__text li, .block--form .form__message li,
.block--editor-form .form__message li, .block--editor-splash .block__text li, .block--quad-callouts .block__text li,
.block--editor-quad-callouts .block__text li, .block--editor-triple-callouts .block__text li,
.block--triple-callouts .block__text li, .block--editor-twin-callouts .block__text li, .block--twin-images .block__text li,
.block--editor-twin-images .block__text li, .block--image .block__text li,
.block--editor-single-image .block__text li, .block--richtext .block__panel--stylised .block__text li,
.block--editor-richtext .block__panel--stylised .block__text li,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text li, .block--richtext .block__text li,
.block--editor-richtext .block__text li,
.block--editor-rich-text-with-cta .block__text li, .block--editor-heading .block__text li, .block--editor-feature .block__text li, .block--editor-feature .block__intro li {
  margin: 0 0 0.75em;
}
.richtext ul li, .block--featured-post .block__text ul li, .block--editor-newsletter-signup .block__text ul li, .block--form .form__message ul li,
.block--editor-form .form__message ul li, .block--editor-splash .block__text ul li, .block--quad-callouts .block__text ul li,
.block--editor-quad-callouts .block__text ul li, .block--editor-triple-callouts .block__text ul li,
.block--triple-callouts .block__text ul li, .block--editor-twin-callouts .block__text ul li, .block--twin-images .block__text ul li,
.block--editor-twin-images .block__text ul li, .block--image .block__text ul li,
.block--editor-single-image .block__text ul li, .block--richtext .block__panel--stylised .block__text ul li,
.block--editor-richtext .block__panel--stylised .block__text ul li,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ul li, .block--richtext .block__text ul li,
.block--editor-richtext .block__text ul li,
.block--editor-rich-text-with-cta .block__text ul li, .block--editor-heading .block__text ul li, .block--editor-feature .block__text ul li, .block--editor-feature .block__intro ul li,
.richtext ol li,
.block--featured-post .block__text ol li,
.block--editor-newsletter-signup .block__text ol li,
.block--form .form__message ol li,
.block--editor-form .form__message ol li,
.block--editor-splash .block__text ol li,
.block--quad-callouts .block__text ol li,
.block--editor-quad-callouts .block__text ol li,
.block--editor-triple-callouts .block__text ol li,
.block--triple-callouts .block__text ol li,
.block--editor-twin-callouts .block__text ol li,
.block--twin-images .block__text ol li,
.block--editor-twin-images .block__text ol li,
.block--image .block__text ol li,
.block--editor-single-image .block__text ol li,
.block--richtext .block__panel--stylised .block__text ol li,
.block--editor-richtext .block__panel--stylised .block__text ol li,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text ol li,
.block--richtext .block__text ol li,
.block--editor-richtext .block__text ol li,
.block--editor-rich-text-with-cta .block__text ol li,
.block--editor-heading .block__text ol li,
.block--editor-feature .block__text ol li,
.block--editor-feature .block__intro ol li {
  padding-left: 3px;
}
.richtext dl, .block--featured-post .block__text dl, .block--editor-newsletter-signup .block__text dl, .block--form .form__message dl,
.block--editor-form .form__message dl, .block--editor-splash .block__text dl, .block--quad-callouts .block__text dl,
.block--editor-quad-callouts .block__text dl, .block--editor-triple-callouts .block__text dl,
.block--triple-callouts .block__text dl, .block--editor-twin-callouts .block__text dl, .block--twin-images .block__text dl,
.block--editor-twin-images .block__text dl, .block--image .block__text dl,
.block--editor-single-image .block__text dl, .block--richtext .block__panel--stylised .block__text dl,
.block--editor-richtext .block__panel--stylised .block__text dl,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text dl, .block--richtext .block__text dl,
.block--editor-richtext .block__text dl,
.block--editor-rich-text-with-cta .block__text dl, .block--editor-heading .block__text dl, .block--editor-feature .block__text dl, .block--editor-feature .block__intro dl {
  margin: 0 2em 1em 2.5em;
}
.richtext dt, .block--featured-post .block__text dt, .block--editor-newsletter-signup .block__text dt, .block--form .form__message dt,
.block--editor-form .form__message dt, .block--editor-splash .block__text dt, .block--quad-callouts .block__text dt,
.block--editor-quad-callouts .block__text dt, .block--editor-triple-callouts .block__text dt,
.block--triple-callouts .block__text dt, .block--editor-twin-callouts .block__text dt, .block--twin-images .block__text dt,
.block--editor-twin-images .block__text dt, .block--image .block__text dt,
.block--editor-single-image .block__text dt, .block--richtext .block__panel--stylised .block__text dt,
.block--editor-richtext .block__panel--stylised .block__text dt,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text dt, .block--richtext .block__text dt,
.block--editor-richtext .block__text dt,
.block--editor-rich-text-with-cta .block__text dt, .block--editor-heading .block__text dt, .block--editor-feature .block__text dt, .block--editor-feature .block__intro dt {
  font-weight: bold;
  margin: 1em 0 0.4em 0;
}
.richtext dd, .block--featured-post .block__text dd, .block--editor-newsletter-signup .block__text dd, .block--form .form__message dd,
.block--editor-form .form__message dd, .block--editor-splash .block__text dd, .block--quad-callouts .block__text dd,
.block--editor-quad-callouts .block__text dd, .block--editor-triple-callouts .block__text dd,
.block--triple-callouts .block__text dd, .block--editor-twin-callouts .block__text dd, .block--twin-images .block__text dd,
.block--editor-twin-images .block__text dd, .block--image .block__text dd,
.block--editor-single-image .block__text dd, .block--richtext .block__panel--stylised .block__text dd,
.block--editor-richtext .block__panel--stylised .block__text dd,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text dd, .block--richtext .block__text dd,
.block--editor-richtext .block__text dd,
.block--editor-rich-text-with-cta .block__text dd, .block--editor-heading .block__text dd, .block--editor-feature .block__text dd, .block--editor-feature .block__intro dd {
  line-height: 1.3em;
  margin-bottom: 0.5em;
}
.richtext a, .block--featured-post .block__text a, .block--editor-newsletter-signup .block__text a, .block--form .form__message a,
.block--editor-form .form__message a, .block--editor-splash .block__text a, .block--quad-callouts .block__text a,
.block--editor-quad-callouts .block__text a, .block--editor-triple-callouts .block__text a,
.block--triple-callouts .block__text a, .block--editor-twin-callouts .block__text a, .block--twin-images .block__text a,
.block--editor-twin-images .block__text a, .block--image .block__text a,
.block--editor-single-image .block__text a, .block--richtext .block__panel--stylised .block__text a,
.block--editor-richtext .block__panel--stylised .block__text a,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text a, .block--richtext .block__text a,
.block--editor-richtext .block__text a,
.block--editor-rich-text-with-cta .block__text a, .block--editor-heading .block__text a, .block--editor-feature .block__text a, .block--editor-feature .block__intro a {
  font-weight: 700;
  margin: -4px;
  padding: 4px;
  color: #000000;
  text-decoration: none;
}
.richtext a:hover, .block--featured-post .block__text a:hover, .block--editor-newsletter-signup .block__text a:hover, .block--form .form__message a:hover,
.block--editor-form .form__message a:hover, .block--editor-splash .block__text a:hover, .block--quad-callouts .block__text a:hover,
.block--editor-quad-callouts .block__text a:hover, .block--editor-triple-callouts .block__text a:hover,
.block--triple-callouts .block__text a:hover, .block--editor-twin-callouts .block__text a:hover, .block--twin-images .block__text a:hover,
.block--editor-twin-images .block__text a:hover, .block--image .block__text a:hover,
.block--editor-single-image .block__text a:hover, .block--richtext .block__text a:hover,
.block--editor-richtext .block__text a:hover,
.block--editor-rich-text-with-cta .block__text a:hover, .block--editor-heading .block__text a:hover, .block--editor-feature .block__text a:hover, .block--editor-feature .block__intro a:hover {
  text-decoration: underline;
}
.richtext hr, .block--featured-post .block__text hr, .block--editor-newsletter-signup .block__text hr, .block--form .form__message hr,
.block--editor-form .form__message hr, .block--editor-splash .block__text hr, .block--quad-callouts .block__text hr,
.block--editor-quad-callouts .block__text hr, .block--editor-triple-callouts .block__text hr,
.block--triple-callouts .block__text hr, .block--editor-twin-callouts .block__text hr, .block--twin-images .block__text hr,
.block--editor-twin-images .block__text hr, .block--image .block__text hr,
.block--editor-single-image .block__text hr, .block--richtext .block__panel--stylised .block__text hr,
.block--editor-richtext .block__panel--stylised .block__text hr,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text hr, .block--richtext .block__text hr,
.block--editor-richtext .block__text hr,
.block--editor-rich-text-with-cta .block__text hr, .block--editor-heading .block__text hr, .block--editor-feature .block__text hr, .block--editor-feature .block__intro hr {
  border: 0;
  height: 1px;
  background-color: #bdbdbd;
  margin: 1.875em 0;
}
.richtext blockquote, .block--featured-post .block__text blockquote, .block--editor-newsletter-signup .block__text blockquote, .block--form .form__message blockquote,
.block--editor-form .form__message blockquote, .block--editor-splash .block__text blockquote, .block--quad-callouts .block__text blockquote,
.block--editor-quad-callouts .block__text blockquote, .block--editor-triple-callouts .block__text blockquote,
.block--triple-callouts .block__text blockquote, .block--editor-twin-callouts .block__text blockquote, .block--twin-images .block__text blockquote,
.block--editor-twin-images .block__text blockquote, .block--image .block__text blockquote,
.block--editor-single-image .block__text blockquote, .block--richtext .block__panel--stylised .block__text blockquote,
.block--editor-richtext .block__panel--stylised .block__text blockquote,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text blockquote, .block--richtext .block__text blockquote,
.block--editor-richtext .block__text blockquote,
.block--editor-rich-text-with-cta .block__text blockquote, .block--editor-heading .block__text blockquote, .block--editor-feature .block__text blockquote, .block--editor-feature .block__intro blockquote {
  margin: 0 0 1.875em;
}
.richtext blockquote p:last-child, .block--featured-post .block__text blockquote p:last-child, .block--editor-newsletter-signup .block__text blockquote p:last-child, .block--form .form__message blockquote p:last-child,
.block--editor-form .form__message blockquote p:last-child, .block--editor-splash .block__text blockquote p:last-child, .block--quad-callouts .block__text blockquote p:last-child,
.block--editor-quad-callouts .block__text blockquote p:last-child, .block--editor-triple-callouts .block__text blockquote p:last-child,
.block--triple-callouts .block__text blockquote p:last-child, .block--editor-twin-callouts .block__text blockquote p:last-child, .block--twin-images .block__text blockquote p:last-child,
.block--editor-twin-images .block__text blockquote p:last-child, .block--image .block__text blockquote p:last-child,
.block--editor-single-image .block__text blockquote p:last-child, .block--richtext .block__text blockquote p:last-child,
.block--editor-richtext .block__text blockquote p:last-child,
.block--editor-rich-text-with-cta .block__text blockquote p:last-child, .block--editor-heading .block__text blockquote p:last-child, .block--editor-feature .block__text blockquote p:last-child, .block--editor-feature .block__intro blockquote p:last-child {
  margin-bottom: 0;
}

.richtext--center {
  text-align: center;
}

.richtext--document h1, .richtext--document h2, .richtext--document h3, .richtext--document h4, .richtext--document h5, .richtext--document h6 {
  margin-bottom: 0.5em;
  margin-top: 1.875em;
}
.richtext--document h1:first-child, .richtext--document h2:first-child, .richtext--document h3:first-child, .richtext--document h4:first-child, .richtext--document h5:first-child, .richtext--document h6:first-child {
  margin-top: 0;
}
.richtext--document p {
  margin-bottom: 0.8em;
}
.richtext--document ul li:before {
  color: #000000;
}

.richtext--large, .block--editor-newsletter-signup .block__text, .block--form .form__message,
.block--editor-form .form__message, .block--editor-splash .block__text, .block--editor-twin-callouts .block__text, .block--twin-images .block__text,
.block--editor-twin-images .block__text, .block--richtext .block__text,
.block--editor-richtext .block__text,
.block--editor-rich-text-with-cta .block__text, .block--editor-heading .block__text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.richtext--large p, .block--editor-newsletter-signup .block__text p, .block--form .form__message p,
.block--editor-form .form__message p, .block--editor-splash .block__text p, .block--editor-twin-callouts .block__text p, .block--twin-images .block__text p,
.block--editor-twin-images .block__text p, .block--richtext .block__text p,
.block--editor-richtext .block__text p,
.block--editor-rich-text-with-cta .block__text p, .block--editor-heading .block__text p {
  margin-bottom: 1.3333333333em;
}

.richtext--small {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
.richtext--small p {
  margin-bottom: 0.7142857143em;
}

@media screen and (max-width: 62.4375em) {
  .richtext--m-small {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5714285714;
  }
}
@media screen and (max-width: 62.4375em) {
  .richtext--m-small p {
    margin-bottom: 0.7142857143em;
  }
}

@media screen and (max-width: 62.4375em) {
  .richtext--m-normal {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.375;
  }
}
@media screen and (max-width: 62.4375em) {
  .richtext--m-normal p {
    margin-bottom: 0.625em;
  }
}

.richtext--flush > :last-child, .block--featured-post .block__text > :last-child, .block--editor-newsletter-signup .block__text > :last-child, .block--form .form__message > :last-child,
.block--editor-form .form__message > :last-child, .block--editor-splash .block__text > :last-child, .block--quad-callouts .block__text > :last-child,
.block--editor-quad-callouts .block__text > :last-child, .block--editor-triple-callouts .block__text > :last-child,
.block--triple-callouts .block__text > :last-child, .block--editor-twin-callouts .block__text > :last-child, .block--twin-images .block__text > :last-child,
.block--editor-twin-images .block__text > :last-child, .block--image .block__text > :last-child,
.block--editor-single-image .block__text > :last-child, .block--richtext .block__text > :last-child,
.block--editor-richtext .block__text > :last-child,
.block--editor-rich-text-with-cta .block__text > :last-child, .block--editor-heading .block__text > :last-child, .block--editor-feature .block__text > :last-child, .block--editor-feature .block__intro > :last-child {
  margin-bottom: 0;
}

.hero {
  position: relative;
}
.header--white ~ #main .hero:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
  z-index: 1;
}

.hero__video {
  overflow: hidden;
  position: relative;
}
.hero__video:before {
  float: left;
  content: "";
  padding-bottom: 56.25%;
  margin-bottom: 0px;
}
.hero__video:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .hero__video {
    margin-bottom: 15px;
  }
  .hero__video:last-child {
    margin-bottom: 0;
  }
}
.hero__video iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.hero__video img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 42.5em) {
  .hero__video--mobile + .hero__video {
    display: none;
  }
}

.hero__video--mobile {
  display: none;
}
@media screen and (max-width: 42.5em) {
  .hero__video--mobile {
    display: block;
    overflow: hidden;
  }
  .hero__video--mobile:before {
    float: left;
    content: "";
    padding-bottom: 100%;
    margin-bottom: 0px;
  }
  .hero__video--mobile:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}

.hero__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
.hero__play svg {
  display: block;
}
.hero__play span {
  margin-top: 8px;
  display: block;
  text-align: center;
}
@media screen and (max-width: 42.5em) {
  .hero__play span {
    margin-top: 3px;
  }
}

.hero__video-preview {
  display: block;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.hero__video-preview img {
  width: 100%;
  height: 100%;
}

.hero__picture {
  overflow: hidden;
}
.hero__picture:before {
  float: left;
  content: "";
  padding-bottom: 60%;
  margin-bottom: 0px;
}
.hero__picture:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .hero__picture {
    overflow: hidden;
    margin-bottom: 25px;
  }
  .hero__picture:before {
    float: left;
    content: "";
    padding-bottom: 100%;
    margin-bottom: 0px;
  }
  .hero__picture:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
  .hero__picture:last-child {
    margin-bottom: 0;
  }
}
.hero__picture img {
  width: 100%;
  height: auto;
  max-width: none;
}

.hero__content {
  position: absolute;
  color: #FFFFFF;
  pointer-events: none;
}
.hero__content a {
  pointer-events: all;
}
@media screen and (max-width: 42.5em) {
  .hero__content {
    position: static;
    color: inherit;
  }
}
.hero--simple .hero__content {
  position: static;
  bottom: 0;
  color: inherit;
}

.hero .hero__content .button {
  margin-right: 10px;
}
@media screen and (max-width: 62.4375em) {
  .hero .hero__content .hero__title {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.0952380952;
  }
}
@media screen and (max-width: 62.4375em) and (max-width: 42.5em) {
  .hero .hero__content .button {
    color: #FFFFFF;
    background-color: #000000;
    border: 1px solid #000000;
  }
  .hero .hero__content .button:hover, .hero .hero__content .button:active {
    color: #000000;
    background-color: #FFFFFF;
  }
}
@media screen and (max-width: 62.4375em) and (max-width: 42.5em) {
  .hero .hero__content .button.button--transparent {
    background: transparent;
    color: #000000;
  }
  .hero .hero__content .button.button--transparent:hover, .hero .hero__content .button.button--transparent:active {
    color: #FFFFFF;
    background-color: #000000;
  }
  .hero .hero__content .button.button--transparent.btn-alt {
    color: #FFFFFF;
  }
  .hero .hero__content .button.button--transparent.btn-alt:hover, .hero .hero__content .button.button--transparent.btn-alt:active {
    color: #000000;
    background-color: #FFFFFF;
  }
}

.hero.hero--text-centred .hero__content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.hero.hero--text-centred .hero__content .hero__intro {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-centred .hero__content {
    transform: unset;
    text-align: unset;
    max-width: unset;
  }
  .hero.hero--text-centred .hero__content .hero__intro {
    margin-left: unset;
    margin-right: unset;
  }
}

.hero.hero--text-left-centred .hero__content {
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  max-width: 50%;
}
@media screen and (max-width: 62.4375em) {
  .hero.hero--text-left-centred .hero__content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-left-centred .hero__content {
    transform: unset;
    max-width: unset;
  }
}

.hero.hero--text-right-centred .hero__content {
  top: 50%;
  left: unset;
  right: 0;
  transform: translate(0%, -50%);
  max-width: 50%;
  text-align: right;
}
@media screen and (max-width: 62.4375em) {
  .hero.hero--text-right-centred .hero__content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-right-centred .hero__content {
    transform: unset;
    max-width: unset;
    text-align: left;
  }
}
.hero.hero--text-right-centred .hero__content .hero__intro {
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-right-centred .hero__content .hero__intro {
    margin-left: unset;
    margin-right: unset;
  }
}

.hero.hero--text-centred-bottom .hero__content {
  top: unset;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, 0%);
  text-align: center;
  max-width: 75%;
}
.hero.hero--text-centred-bottom .hero__content .hero__intro {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-centred-bottom .hero__content {
    transform: unset;
    text-align: unset;
    max-width: unset;
  }
  .hero.hero--text-centred-bottom .hero__content .hero__intro {
    margin-left: unset;
    margin-right: unset;
  }
}

.hero.hero--text-left-bottom .hero__content {
  bottom: 50px;
  left: 0;
  max-width: 50%;
}
@media screen and (max-width: 62.4375em) {
  .hero.hero--text-left-bottom .hero__content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-left-bottom .hero__content {
    transform: unset;
    max-width: unset;
  }
}

.hero.hero--text-right-bottom .hero__content {
  left: unset;
  right: 0;
  bottom: 50px;
  max-width: 50%;
  text-align: right;
}
@media screen and (max-width: 62.4375em) {
  .hero.hero--text-right-bottom .hero__content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-right-bottom .hero__content {
    transform: unset;
    max-width: unset;
    text-align: left;
  }
}
.hero.hero--text-right-bottom .hero__content .hero__intro {
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 42.5em) {
  .hero.hero--text-right-bottom .hero__content .hero__intro {
    margin-left: unset;
    margin-right: unset;
  }
}

.hero.hero--size-short .hero__picture,
.hero.hero--size-short .hero__video {
  overflow: hidden;
}
.hero.hero--size-short .hero__picture:before,
.hero.hero--size-short .hero__video:before {
  float: left;
  content: "";
  padding-bottom: 50%;
  margin-bottom: 0px;
}
.hero.hero--size-short .hero__picture:after,
.hero.hero--size-short .hero__video:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}

.hero__content--video {
  z-index: 11;
  bottom: 70px;
}

.hero__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.1666666667;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 10px;
}
@media screen and (max-width: 42.5em) {
  .hero__title {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.1428571429;
  }
}
.hero__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .hero__title {
    margin-top: 15px;
  }
  .hero__title:last-child {
    margin-top: 0;
  }
}
.hero--simple .hero__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.1666666667;
  margin-bottom: 17px;
}
@media screen and (max-width: 42.5em) {
  .hero--simple .hero__title {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.1428571429;
  }
}
.hero--simple .hero__title:last-child {
  margin-bottom: 0;
}

.hero__intro {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 20px;
  max-width: 500px;
}
.hero__intro:last-child {
  margin-bottom: 0;
}
.hero--simple .hero__intro {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 20px;
  max-width: 795px;
}
@media screen and (max-width: 42.5em) {
  .hero--simple .hero__intro {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
}
.hero--simple .hero__intro:last-child {
  margin-bottom: 0;
}
.hero--simple .hero__intro a {
  margin: -4px;
  padding: 4px;
  color: #000000;
}

.hero--light-on-dark {
  color: #FFFFFF;
}
@media screen and (max-width: 42.5em) {
  .hero--light-on-dark {
    color: #000000;
  }
}

.hero--dark-on-light {
  color: #000000;
}

.hero--gradient-dark {
  position: relative;
}
.hero--text-centred .hero--gradient-dark picture:before {
  content: ".";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 42.5em) {
  .hero--text-centred .hero--gradient-dark picture:before {
    content: none;
  }
}
.hero--text-left-centred .hero--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-left-centred .hero--gradient-dark picture:before {
    content: none;
  }
}
.hero--text-left-bottom .hero--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-left-bottom .hero--gradient-dark picture:before {
    content: none;
  }
}
.hero--text-left-bottom .hero--gradient-dark picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-left-bottom .hero--gradient-dark picture:after {
    content: none;
  }
}
.hero--text-right-centred .hero--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-right-centred .hero--gradient-dark picture:before {
    content: none;
  }
}
.hero--text-right-bottom .hero--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-right-bottom .hero--gradient-dark picture:before {
    content: none;
  }
}
.hero--text-right-bottom .hero--gradient-dark picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-right-bottom .hero--gradient-dark picture:after {
    content: none;
  }
}
.hero--text-centred-bottom .hero--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-centred-bottom .hero--gradient-dark picture:before {
    content: none;
  }
}

.hero--gradient-light {
  position: relative;
}
.hero--text-centred .hero--gradient-light picture:before {
  content: ".";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 42.5em) {
  .hero--text-centred .hero--gradient-light picture:before {
    content: none;
  }
}
.hero--text-left-centred .hero--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-left-centred .hero--gradient-light picture:before {
    content: none;
  }
}
.hero--text-left-bottom .hero--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-left-bottom .hero--gradient-light picture:before {
    content: none;
  }
}
.hero--text-left-bottom .hero--gradient-light picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-left-bottom .hero--gradient-light picture:after {
    content: none;
  }
}
.hero--text-right-centred .hero--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-right-centred .hero--gradient-light picture:before {
    content: none;
  }
}
.hero--text-right-bottom .hero--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-right-bottom .hero--gradient-light picture:before {
    content: none;
  }
}
.hero--text-right-bottom .hero--gradient-light picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-right-bottom .hero--gradient-light picture:after {
    content: none;
  }
}
.hero--text-centred-bottom .hero--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .hero--text-centred-bottom .hero--gradient-light picture:before {
    content: none;
  }
}

.anchor-menu {
  padding-top: 92px;
  padding-bottom: 33px;
}

.anchor-menu--sticky {
  position: fixed;
  padding: 0;
  top: 0;
  width: 100%;
  z-index: 900;
  background: #F0EEEB;
  top: 56px;
}
.body--debug .anchor-menu--sticky {
  top: 76px;
}
.body--admin .anchor-menu--sticky {
  top: 100px;
}
.body--debug.body--admin .anchor-menu--sticky {
  top: 120px;
}
.anchor-menu--sticky.notice-locale--exists {
  top: 56px;
}
.body--debug .anchor-menu--sticky.notice-locale--exists {
  top: 76px;
}
.body--admin .anchor-menu--sticky.notice-locale--exists {
  top: 100px;
}
.body--debug.body--admin .anchor-menu--sticky.notice-locale--exists {
  top: 120px;
}
@media screen and (max-width: 62.4375em) {
  .anchor-menu--sticky {
    position: sticky;
  }
}
@media screen and (max-width: 42.5em) {
  .body--admin .anchor-menu--sticky, .body--debug .anchor-menu--sticky, .body--admin.body--debug .anchor-menu--sticky {
    top: 60px;
  }
}

.anchor-menu__items-track {
  display: inline-block;
}
@media screen and (max-width: 62.4375em) {
  .anchor-menu--sticky .anchor-menu__items-track {
    overflow-x: auto;
    display: block;
    margin: 0 -16px;
  }
  .anchor-menu__items-track .anchor-menu__items {
    margin: 0 16px;
  }
}

.anchor-menu__title {
  font-family: Cardo, serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 62px;
  font-size: 3.875rem;
  line-height: 1.1290322581;
  letter-spacing: 0;
  line-height: 50px;
}
.anchor-menu--sticky .anchor-menu__title {
  display: inline-block;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.2941176471;
  margin-bottom: 0;
  margin-right: 20px;
}
@media screen and (max-width: 62.4375em) {
  .anchor-menu--sticky .anchor-menu__title {
    margin: 16px 0 8px;
  }
}
@media screen and (max-width: 62.4375em) {
  .anchor-menu__title {
    display: none;
  }
}

.anchor-menu__items {
  margin-top: 20px;
  list-style: none;
}
.anchor-menu--sticky .anchor-menu__items {
  display: inline-block;
  margin-top: 0;
  padding: 16px 0;
}
@media screen and (max-width: 62.4375em) {
  .anchor-menu__items {
    white-space: nowrap;
  }
}

.anchor-menu__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 8px;
}
.anchor-menu__item:first-child {
  margin-left: 0;
}
.anchor-menu__item:last-child {
  margin-right: 0;
}

.anchor-menu__link {
  position: relative;
  color: inherit;
  text-decoration: none;
  font-size: 17px;
  font-size: 1.0625rem;
  letter-spacing: 0;
  line-height: 22px;
}
.anchor-menu--sticky .anchor-menu__link {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
}
.anchor-menu__link:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 1px;
  background: #000000;
  transition: width 0.3s ease;
}
.anchor-menu__link:hover {
  text-decoration: none;
}
@media (hover: hover) {
  .anchor-menu__link:hover::after {
    width: 100%;
  }
}

.anchor-menu__link--active::after {
  background: #000000;
  width: 100%;
}

.block--banner {
  position: relative;
}
.block--banner a {
  pointer-events: all;
}
.block--banner a:not(.button) {
  text-decoration: none;
  color: inherit;
}
.block--banner a:not(.button):hover {
  text-decoration: underline;
}
.block--banner .inner-wrap {
  position: absolute;
  bottom: 50px;
  left: 0;
  color: #FFFFFF;
  pointer-events: none;
}
.block--banner .block__panel {
  max-width: 50%;
  margin: 0 auto;
  padding: 0;
}
@media screen and (max-width: 42.5em) {
  .block--banner .block__panel {
    max-width: unset;
    margin: unset;
  }
}
.block--banner .block__image {
  overflow: hidden;
}
.block--banner .block__image:before {
  float: left;
  content: "";
  padding-bottom: 60%;
  margin-bottom: 0px;
}
.block--banner .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .block--banner .block__image {
    overflow: hidden;
    margin-bottom: 25px;
  }
  .block--banner .block__image:before {
    float: left;
    content: "";
    padding-bottom: 100%;
    margin-bottom: 0px;
  }
  .block--banner .block__image:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
  .block--banner .block__image:last-child {
    margin-bottom: 0;
  }
}
.block--banner .block__image img,
.block--banner .block__image picture {
  width: 100%;
  height: auto;
  max-width: none;
}
.block--banner.banner--style-short .block__image {
  overflow: hidden;
}
.block--banner.banner--style-short .block__image:before {
  float: left;
  content: "";
  padding-bottom: 50%;
  margin-bottom: 0px;
}
.block--banner.banner--style-short .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--banner.banner--style-extreme .block__image, .block--banner.banner--style-narrow-extreme .block__image {
  overflow: hidden;
}
.block--banner.banner--style-extreme .block__image:before, .block--banner.banner--style-narrow-extreme .block__image:before {
  float: left;
  content: "";
  padding-bottom: 28.5714285714%;
  margin-bottom: 0px;
}
.block--banner.banner--style-extreme .block__image:after, .block--banner.banner--style-narrow-extreme .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .block--banner.banner--style-extreme .block__image, .block--banner.banner--style-narrow-extreme .block__image {
    overflow: hidden;
  }
  .block--banner.banner--style-extreme .block__image:before, .block--banner.banner--style-narrow-extreme .block__image:before {
    float: left;
    content: "";
    padding-bottom: 75%;
    margin-bottom: 0px;
  }
  .block--banner.banner--style-extreme .block__image:after, .block--banner.banner--style-narrow-extreme .block__image:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--banner .block__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.2;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 24px;
}
.block--banner .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--banner .block__title {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.0952380952;
  }
}
.block--banner .block__intro {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 24px;
  max-width: 500px;
}
.block--banner .block__intro:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .block--banner .block__intro {
    max-width: unset;
  }
}
.block--banner .block__button-wrap {
  margin-bottom: 24px;
  max-width: 500px;
}
.block--banner .block__button-wrap:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .block--banner .block__button-wrap {
    max-width: unset;
    margin-left: unset;
    margin-right: unset;
    text-align: unset;
  }
}
.block--banner .block__button {
  position: relative;
  z-index: 11;
  margin-right: 10px;
}
.block--banner .block__button:hover, .block--banner .block__button:active {
  color: #FFFFFF;
  background-color: #17181A;
}
.block--banner .block__button.button--alt:hover, .block--banner .block__button.button--alt:active {
  color: #000000;
  background-color: #FFFFFF;
}

.banner--gradient-light {
  position: relative;
}
.banner--text-centred .banner--gradient-light picture:before {
  content: ".";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 42.5em) {
  .banner--text-centred .banner--gradient-light picture:before {
    content: none;
  }
}
.banner--text-left-centred .banner--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.35), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-left-centred .banner--gradient-light picture:before {
    content: none;
  }
}
.banner--text-left-bottom .banner--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.35), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-left-bottom .banner--gradient-light picture:before {
    content: none;
  }
}
.banner--text-left-bottom .banner--gradient-light picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.35), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-left-bottom .banner--gradient-light picture:after {
    content: none;
  }
}
.banner--text-right-centred .banner--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.35), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-centred .banner--gradient-light picture:before {
    content: none;
  }
}
.banner--text-right-bottom .banner--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.35), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-bottom .banner--gradient-light picture:before {
    content: none;
  }
}
.banner--text-right-bottom .banner--gradient-light picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.35), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-bottom .banner--gradient-light picture:after {
    content: none;
  }
}
.banner--text-centred-bottom .banner--gradient-light picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.35), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-centred-bottom .banner--gradient-light picture:before {
    content: none;
  }
}

.banner--gradient-dark {
  position: relative;
}
.banner--text-centred .banner--gradient-dark picture:before {
  content: ".";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 42.5em) {
  .banner--text-centred .banner--gradient-dark picture:before {
    content: none;
  }
}
.banner--text-left-centred .banner--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-left-centred .banner--gradient-dark picture:before {
    content: none;
  }
}
.banner--text-left-bottom .banner--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-left-bottom .banner--gradient-dark picture:before {
    content: none;
  }
}
.banner--text-left-bottom .banner--gradient-dark picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-left-bottom .banner--gradient-dark picture:after {
    content: none;
  }
}
.banner--text-right-centred .banner--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-centred .banner--gradient-dark picture:before {
    content: none;
  }
}
.banner--text-right-bottom .banner--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-bottom .banner--gradient-dark picture:before {
    content: none;
  }
}
.banner--text-right-bottom .banner--gradient-dark picture:after {
  content: ".";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-bottom .banner--gradient-dark picture:after {
    content: none;
  }
}
.banner--text-centred-bottom .banner--gradient-dark picture:before {
  content: ".";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}
@media screen and (max-width: 42.5em) {
  .banner--text-centred-bottom .banner--gradient-dark picture:before {
    content: none;
  }
}

.block--banner .wrap {
  z-index: 10;
}

.banner--light-on-dark {
  color: #FFFFFF;
  position: relative;
}

.banner--dark-on-light {
  color: #000000;
  position: relative;
}

.banner--text-centred .inner-wrap {
  top: 50%;
  left: 50%;
  bottom: unset;
  transform: translate(-50%, -50%);
  text-align: center;
}
.banner--text-centred .inner-wrap .block__intro {
  max-width: 37.5%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 42.5em) {
  .banner--text-centred .inner-wrap .block__intro {
    max-width: unset;
  }
}
.banner--text-centred .inner-wrap .block__button-wrap,
.banner--text-centred .inner-wrap .block__panel,
.banner--text-centred .inner-wrap .inner-wrap {
  max-width: unset;
}

.banner--text-left-centred .inner-wrap {
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  display: inline-table;
}
.banner--text-left-centred .inner-wrap .block__panel {
  margin: unset;
  margin-right: auto;
}

.banner--text-right-centred .inner-wrap {
  top: 50%;
  left: unset;
  right: 0;
  transform: translate(0%, -50%);
  display: inline-table;
}
.banner--text-right-centred .inner-wrap .block__title,
.banner--text-right-centred .inner-wrap .block__intro,
.banner--text-right-centred .inner-wrap .block__button-wrap {
  text-align: right;
}
.banner--text-right-centred .inner-wrap .block__intro {
  margin-left: auto;
  margin-right: 0;
}
.banner--text-right-centred .inner-wrap .block__button-wrap {
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-centred .inner-wrap .block__button-wrap {
    margin-left: unset;
    margin-right: unset;
  }
}
.banner--text-right-centred .inner-wrap .block__panel {
  margin: unset;
  margin-left: auto;
}

.banner--text-centred-bottom .inner-wrap {
  top: unset;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, 0%);
  text-align: center;
}
.banner--text-centred-bottom .inner-wrap .block__intro {
  max-width: 37.5%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 42.5em) {
  .banner--text-centred-bottom .inner-wrap .block__intro {
    max-width: none;
  }
}
.banner--text-centred-bottom .inner-wrap .block__button-wrap,
.banner--text-centred-bottom .inner-wrap .block__panel,
.banner--text-centred-bottom .inner-wrap .inner-wrap {
  max-width: unset;
}

.banner--text-left-bottom .inner-wrap {
  bottom: 50px;
  left: 0;
}
.banner--text-left-bottom .inner-wrap .block__panel {
  margin: unset;
  margin-right: auto;
}

@media screen and (max-width: 42.5em) {
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap {
    position: static;
    bottom: 0;
    margin-bottom: 48px;
    text-align: unset;
    transform: unset;
  }
}
@media screen and (max-width: 42.5em) {
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__title,
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__intro,
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button-wrap {
    text-align: unset;
  }
}
@media screen and (max-width: 42.5em) {
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__panel {
    margin-left: 0;
  }
}
@media screen and (max-width: 42.5em) {
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button {
    color: #FFFFFF;
    background-color: #000000;
    border: 1px solid #000000;
  }
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button:hover, .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button:active {
    color: #000000;
    background-color: #FFFFFF;
  }
}
@media screen and (max-width: 42.5em) {
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .banner--light-on-dark {
    color: #000000 !important;
  }
}
@media screen and (max-width: 42.5em) {
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button.button--transparent {
    background: transparent;
    color: #000000;
  }
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button.button--transparent:hover, .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button.button--transparent:active {
    color: #FFFFFF;
    background-color: #000000;
  }
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button.button--transparent.btn-alt {
    color: #FFFFFF;
  }
  .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button.button--transparent.btn-alt:hover, .block--banner:not(.banner--style-extreme):not(.banner--style-narrow-extreme) .inner-wrap .block__button.button--transparent.btn-alt:active {
    color: #000000;
    background-color: #FFFFFF;
  }
}

.banner--text-right-bottom .inner-wrap {
  left: unset;
  right: 0;
}
.banner--text-right-bottom .inner-wrap .block__title,
.banner--text-right-bottom .inner-wrap .block__intro,
.banner--text-right-bottom .inner-wrap .block__button-wrap {
  text-align: right;
}
.banner--text-right-bottom .inner-wrap .block__intro {
  margin-left: auto;
  margin-right: 0;
}
.banner--text-right-bottom .inner-wrap .block__button-wrap {
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 42.5em) {
  .banner--text-right-bottom .inner-wrap .block__button-wrap {
    margin-left: unset;
    margin-right: unset;
  }
}
.banner--text-right-bottom .inner-wrap .block__panel {
  margin: unset;
  margin-left: auto;
}

.banner--style-extreme .block__panel .block__title,
.banner--style-narrow-extreme .block__panel .block__title {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.2631578947;
}
@media screen and (max-width: 62.4375em) {
  .banner--style-extreme .block__panel .block__title,
  .banner--style-narrow-extreme .block__panel .block__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.banner--style-narrow-extreme .inner-wrap {
  padding: 0 48px;
}
@media screen and (max-width: 62.4375em) {
  .banner--style-narrow-extreme .inner-wrap {
    padding: 0 24px;
  }
}

.block--editor-feature .block__left {
  flex: 0 0 auto;
}
.block--editor-feature .block__image {
  overflow: hidden;
}
.block--editor-feature .block__image:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--editor-feature .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--editor-feature .block__image img {
  width: 100%;
  height: auto;
}
.block--editor-feature .grid--reverse .block__right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-feature .grid--reverse .block__right {
    display: block;
  }
}
.block--editor-feature .block__panel {
  width: 100%;
  max-width: 668px;
  padding: 64px 24px 24px;
  text-align: left;
  display: inline-block;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-feature .block__panel {
    display: block;
    margin: 0 auto;
    padding: 30px 16px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--editor-feature .block__panel {
    padding-top: 16px;
    padding-bottom: 50px;
  }
}
.block--editor-feature .block__title,
.block--editor-feature .block__intro {
  max-width: 620px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-feature .block__title,
  .block--editor-feature .block__intro {
    max-width: none;
  }
}
.block--editor-feature .block__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
  margin-bottom: 10px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-feature .block__title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2666666667;
  }
}
.block--editor-feature .block__title:last-child {
  margin-bottom: 0;
}
.block--editor-feature .block__intro {
  margin-bottom: 20px;
}
.block--editor-feature .block__intro:last-child {
  margin-bottom: 0;
}
.block--editor-feature .block__text {
  margin-bottom: 20px;
}
.block--editor-feature .block__text:last-child {
  margin-bottom: 0;
}
.block--editor-feature.block--padded-compact .block__image {
  overflow: hidden;
}
.block--editor-feature.block--padded-compact .block__image:before {
  float: left;
  content: "";
  padding-bottom: 25%;
  margin-bottom: -0.75;
}
.block--editor-feature.block--padded-compact .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--editor-feature.block--padded-compact .block__image img {
  margin-top: -1px;
  margin-bottom: -1px;
}
.block--editor-feature.block--padded-compact .block__right {
  text-align: left;
  display: flex;
  justify-content: flex-start;
}
.block--editor-feature.block--padded-compact .block__left {
  padding: 64px 24px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-feature.block--padded-compact .block__left {
    padding: 0;
  }
}
.block--editor-feature.block--padded-compact .block__panel {
  max-width: 840px;
  align-content: center;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-feature.block--padded-compact .block__panel {
    max-width: none;
    padding: 42px 16px 60px 16px;
  }
}
.block--editor-feature.block--padded-compact .grid--reverse .block__right {
  justify-content: flex-end;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-feature.block--padded-compact .grid--reverse .block__panel {
    max-width: none;
    padding-left: 16px;
  }
}
.block--editor-feature.block--padded-compact .block__title {
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: Cardo, serif;
}
@media screen and (max-width: 42.5em) {
  .block--editor-feature.block--padded-compact .block__title {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.0952380952;
  }
}
.block--editor-feature.block--padded-compact .block__title:last-child {
  margin-bottom: 0;
}

.wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 1680px;
  padding: 0 24px;
}
@media screen and (max-width: 62.4375em) {
  .wrap {
    padding: 0 16px;
  }
}

.wrap--flush {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 62.4375em) {
  .wrap--m-flush {
    padding-left: 0;
    padding-right: 0;
  }
}

.wrap--narrow {
  max-width: 760px;
}

.wrap--wide {
  max-width: 1172px;
}

.wrap--full {
  max-width: none;
}

.wrap--relative {
  position: relative;
}

.block--editor-heading .block__panel {
  padding: 0 24px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-heading .block__panel {
    padding: 0 16px;
  }
}
.block--editor-heading .block__title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  max-width: 1156px;
  margin-bottom: 15px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-heading .block__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
.block--editor-heading .block__title:last-child {
  margin-bottom: 0;
}
.block--editor-heading .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 1156px;
}
.block--editor-heading .block__button {
  margin-top: 18px;
}

.block--richtext .block__panel,
.block--editor-richtext .block__panel,
.block--editor-rich-text-with-cta .block__panel {
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 62.4375em) {
  .block--richtext .block__panel,
  .block--editor-richtext .block__panel,
  .block--editor-rich-text-with-cta .block__panel {
    max-width: 840px;
    margin: 0 auto;
    padding: 0 16px;
  }
}
.block--richtext .block__panel--center,
.block--editor-richtext .block__panel--center,
.block--editor-rich-text-with-cta .block__panel--center {
  text-align: center;
}
.block--richtext .block__panel--right,
.block--editor-richtext .block__panel--right,
.block--editor-rich-text-with-cta .block__panel--right {
  text-align: right;
}
.block--richtext .block__title,
.block--editor-richtext .block__title,
.block--editor-rich-text-with-cta .block__title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  margin-bottom: 15px;
}
@media screen and (max-width: 62.4375em) {
  .block--richtext .block__title,
  .block--editor-richtext .block__title,
  .block--editor-rich-text-with-cta .block__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media screen and (max-width: 42.5em) {
  .block--richtext .block__title,
  .block--editor-richtext .block__title,
  .block--editor-rich-text-with-cta .block__title {
    margin-bottom: 10px;
  }
}
.block--richtext .block__title--center,
.block--editor-richtext .block__title--center,
.block--editor-rich-text-with-cta .block__title--center {
  text-align: center;
}
.block--richtext .block__title--right,
.block--editor-richtext .block__title--right,
.block--editor-rich-text-with-cta .block__title--right {
  text-align: right;
}
.block--richtext .block__text,
.block--editor-richtext .block__text,
.block--editor-rich-text-with-cta .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
}
.block--richtext .block__text--center,
.block--editor-richtext .block__text--center,
.block--editor-rich-text-with-cta .block__text--center {
  text-align: center;
}
.block--richtext .block__text--right,
.block--editor-richtext .block__text--right,
.block--editor-rich-text-with-cta .block__text--right {
  text-align: right;
}
.block--richtext .block__button,
.block--editor-richtext .block__button,
.block--editor-rich-text-with-cta .block__button {
  margin-top: 60px;
}
.block--richtext .block__button,
.block--richtext .block__button--center,
.block--editor-richtext .block__button,
.block--editor-richtext .block__button--center,
.block--editor-rich-text-with-cta .block__button,
.block--editor-rich-text-with-cta .block__button--center {
  text-align: center;
}
.block--richtext .block__button--left,
.block--editor-richtext .block__button--left,
.block--editor-rich-text-with-cta .block__button--left {
  text-align: left;
}
.block--richtext .block__panel--stylised .block__title,
.block--editor-richtext .block__panel--stylised .block__title,
.block--editor-rich-text-with-cta .block__panel--stylised .block__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.2631578947;
  margin-bottom: 26px;
}
@media screen and (max-width: 62.4375em) {
  .block--richtext .block__panel--stylised .block__title,
  .block--editor-richtext .block__panel--stylised .block__title,
  .block--editor-rich-text-with-cta .block__panel--stylised .block__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.block--richtext .block__panel--stylised .block__text,
.block--editor-richtext .block__panel--stylised .block__text,
.block--editor-rich-text-with-cta .block__panel--stylised .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 496px;
  margin: 0 auto;
}
@media screen and (max-width: 42.5em) {
  .block--richtext .block__panel--stylised .block__text,
  .block--editor-richtext .block__panel--stylised .block__text,
  .block--editor-rich-text-with-cta .block__panel--stylised .block__text {
    max-width: none;
  }
}
@media screen and (max-width: 42.5em) {
  .block--richtext .block__panel--stylised.block__panel--center,
  .block--editor-richtext .block__panel--stylised.block__panel--center,
  .block--editor-rich-text-with-cta .block__panel--stylised.block__panel--center {
    text-align: left;
  }
}
.block--richtext .block__panel--stylised.block__panel--right .block__text,
.block--editor-richtext .block__panel--stylised.block__panel--right .block__text,
.block--editor-rich-text-with-cta .block__panel--stylised.block__panel--right .block__text {
  margin-right: 0;
}
.block--richtext .block__panel--stylised.block__panel--left .block__text,
.block--editor-richtext .block__panel--stylised.block__panel--left .block__text,
.block--editor-rich-text-with-cta .block__panel--stylised.block__panel--left .block__text {
  margin-left: 0;
}

@media screen and (max-width: 62.4375em) {
  .block--richtext .block__title {
    text-align: left;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--richtext .block__title--center {
    text-align: center;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--richtext .block__title--right {
    text-align: right;
  }
}

.block--quote.block--center,
.block--editor-quote.block--center {
  text-align: center;
}
.block--quote.block--right,
.block--editor-quote.block--right {
  text-align: right;
}
.block--quote.block--center .block__title,
.block--quote.block--center blockquote,
.block--editor-quote.block--center .block__title,
.block--editor-quote.block--center blockquote {
  margin-right: auto;
  margin-left: auto;
}
.block--quote.block--right .block__title,
.block--quote.block--right blockquote,
.block--editor-quote.block--right .block__title,
.block--editor-quote.block--right blockquote {
  margin-left: auto;
}
.block--quote .block__title,
.block--editor-quote .block__title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-bottom: 18px;
}
@media screen and (max-width: 42.5em) {
  .block--quote .block__title,
  .block--editor-quote .block__title {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
  }
}
.block--quote .block__title,
.block--quote blockquote,
.block--editor-quote .block__title,
.block--editor-quote blockquote {
  width: 75%;
}
.block--center .block--quote .block__title, .block--right .block--quote .block__title,
.block--center .block--quote blockquote,
.block--right .block--quote blockquote,
.block--center .block--editor-quote .block__title,
.block--right .block--editor-quote .block__title,
.block--center .block--editor-quote blockquote,
.block--right .block--editor-quote blockquote {
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 62.4375em) {
  .block--quote .block__title,
  .block--quote blockquote,
  .block--editor-quote .block__title,
  .block--editor-quote blockquote {
    width: 100%;
  }
}
.block--quote .block__source,
.block--editor-quote .block__source {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (max-width: 62.4375em) {
  .block--quote .block__source,
  .block--editor-quote .block__source {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5714285714;
  }
}
.block--quote .block__quote,
.block--editor-quote .block__quote {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.2631578947;
  margin-bottom: 24px;
}
@media screen and (max-width: 62.4375em) {
  .block--quote .block__quote,
  .block--editor-quote .block__quote {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.2307692308;
    margin-bottom: 18px;
  }
}

.block--quote {
  margin-left: 25px;
  margin-right: 25px;
}
@media screen and (max-width: 42.5em) {
  .block--quote {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.block--quote .block__quote {
  quotes: "“" "”";
}
.block--quote .block__quote:before {
  content: open-quote;
}
.block--quote .block__quote:after {
  content: close-quote;
}
.block--quote .block__source {
  display: inline-block;
  margin-bottom: 25px;
}

.block--image .image-right,
.block--image .image-left,
.block--editor-single-image .image-right,
.block--editor-single-image .image-left {
  padding: 0 47px;
}
@media screen and (max-width: 62.4375em) {
  .block--image .image-right,
  .block--image .image-left,
  .block--editor-single-image .image-right,
  .block--editor-single-image .image-left {
    padding: 0 16px;
  }
}
.block--image .image-right .grid,
.block--image .image-left .grid,
.block--editor-single-image .image-right .grid,
.block--editor-single-image .image-left .grid {
  margin-left: -47px;
}
@media screen and (max-width: 62.4375em) {
  .block--image .image-right .grid,
  .block--image .image-left .grid,
  .block--editor-single-image .image-right .grid,
  .block--editor-single-image .image-left .grid {
    margin-left: -25px;
  }
}
.block--image .image-right .grid > *,
.block--image .image-left .grid > *,
.block--editor-single-image .image-right .grid > *,
.block--editor-single-image .image-left .grid > * {
  padding-left: 47px;
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--image .image-right .grid > *,
  .block--image .image-left .grid > *,
  .block--editor-single-image .image-right .grid > *,
  .block--editor-single-image .image-left .grid > * {
    margin-bottom: 16px;
    padding-left: 25px;
  }
}
.block--image .image-top-compact,
.block--image .image-left,
.block--image .image-right,
.block--editor-single-image .image-top-compact,
.block--editor-single-image .image-left,
.block--editor-single-image .image-right {
  max-width: 1172px;
}
.block--image .image-top-compact .grid > *,
.block--editor-single-image .image-top-compact .grid > * {
  max-width: 448px;
}
@media screen and (max-width: 62.4375em) {
  .block--image .image-top-compact .grid > *,
  .block--editor-single-image .image-top-compact .grid > * {
    max-width: none;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--image .text-center,
  .block--image .text-right,
  .block--editor-single-image .text-center,
  .block--editor-single-image .text-right {
    text-align: left;
  }
}
.block--image .block__title,
.block--editor-single-image .block__title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1;
  font-style: normal;
  margin-bottom: 22px;
}
@media screen and (max-width: 62.4375em) {
  .block--image .block__title,
  .block--editor-single-image .block__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 10px;
  }
}
.block--image .block__image,
.block--editor-single-image .block__image {
  overflow: hidden;
}
.block--image .block__image:before,
.block--editor-single-image .block__image:before {
  float: left;
  content: "";
  padding-bottom: 60%;
  margin-bottom: 0px;
}
.block--image .block__image:after,
.block--editor-single-image .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--image .block__image,
  .block--editor-single-image .block__image {
    margin-left: auto;
    margin-right: auto;
  }
}
.block--image .block__image img,
.block--editor-single-image .block__image img {
  width: 100%;
  height: auto;
}
.block--image .block__text,
.block--editor-single-image .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
}
.block--image .block__text ul li,
.block--editor-single-image .block__text ul li {
  margin-bottom: 10px;
}
.block--image .block__text ul li:last-child,
.block--editor-single-image .block__text ul li:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--image .block__text,
  .block--editor-single-image .block__text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5714285714;
  }
}
@media screen and (max-width: 42.5em) {
  .block--image .block__image:last-child,
  .block--image .block__text:last-child,
  .block--editor-single-image .block__image:last-child,
  .block--editor-single-image .block__text:last-child {
    margin-bottom: -10px;
  }
}
.block--image .block__button,
.block--editor-single-image .block__button {
  margin-top: 18px;
}
.block--image .image-top .block__title,
.block--image .image-top-compact .block__title,
.block--image .image-top-tall .block__title,
.block--editor-single-image .image-top .block__title,
.block--editor-single-image .image-top-compact .block__title,
.block--editor-single-image .image-top-tall .block__title {
  font-family: Cardo, serif;
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.2631578947;
}
@media screen and (max-width: 62.4375em) {
  .block--image .image-top .block__title,
  .block--image .image-top-compact .block__title,
  .block--image .image-top-tall .block__title,
  .block--editor-single-image .image-top .block__title,
  .block--editor-single-image .image-top-compact .block__title,
  .block--editor-single-image .image-top-tall .block__title {
    margin-bottom: 18px;
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.block--image .image-top .block__image,
.block--image .image-top-compact .block__image,
.block--image .image-top-tall .block__image,
.block--editor-single-image .image-top .block__image,
.block--editor-single-image .image-top-compact .block__image,
.block--editor-single-image .image-top-tall .block__image {
  margin-bottom: 30px;
}
.block--image .image-top .block__image:last-child,
.block--image .image-top-compact .block__image:last-child,
.block--image .image-top-tall .block__image:last-child,
.block--editor-single-image .image-top .block__image:last-child,
.block--editor-single-image .image-top-compact .block__image:last-child,
.block--editor-single-image .image-top-tall .block__image:last-child {
  margin-bottom: 0;
}
.block--image .image-left .block__image,
.block--image .image-right .block__image,
.block--editor-single-image .image-left .block__image,
.block--editor-single-image .image-right .block__image {
  overflow: hidden;
}
.block--image .image-left .block__image:before,
.block--image .image-right .block__image:before,
.block--editor-single-image .image-left .block__image:before,
.block--editor-single-image .image-right .block__image:before {
  float: left;
  content: "";
  padding-bottom: 75%;
  margin-bottom: 0px;
}
.block--image .image-left .block__image:after,
.block--image .image-right .block__image:after,
.block--editor-single-image .image-left .block__image:after,
.block--editor-single-image .image-right .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--image .image-top-tall .block__image,
.block--editor-single-image .image-top-tall .block__image {
  overflow: hidden;
}
.block--image .image-top-tall .block__image:before,
.block--editor-single-image .image-top-tall .block__image:before {
  float: left;
  content: "";
  padding-bottom: 77.7777777778%;
  margin-bottom: 0px;
}
.block--image .image-top-tall .block__image:after,
.block--editor-single-image .image-top-tall .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}

.block--twin-images .block__title,
.block--editor-twin-images .block__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
  margin-bottom: 20px;
}
@media screen and (max-width: 42.5em) {
  .block--twin-images .block__title,
  .block--editor-twin-images .block__title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2666666667;
  }
}
@media screen and (max-width: 42.5em) {
  .block--twin-images .block__title,
  .block--editor-twin-images .block__title {
    margin-bottom: 10px;
  }
}
.block--twin-images .block__intro,
.block--editor-twin-images .block__intro {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 20px;
  max-width: 795px;
}
@media screen and (max-width: 42.5em) {
  .block--twin-images .block__intro,
  .block--editor-twin-images .block__intro {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
}
.block--twin-images .block__intro:last-child,
.block--editor-twin-images .block__intro:last-child {
  margin-bottom: 0;
}
.block--twin-images .block__image,
.block--editor-twin-images .block__image {
  margin-bottom: 15px;
}
.block--twin-images .block__image:last-child,
.block--editor-twin-images .block__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--twin-images .block__image,
  .block--editor-twin-images .block__image {
    margin-right: -16px;
    margin-left: -16px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--twin-images .block__image,
  .block--editor-twin-images .block__image {
    margin-bottom: 10px;
  }
  .block--twin-images .block__image:last-child,
  .block--editor-twin-images .block__image:last-child {
    margin-bottom: 0;
  }
}
.block--twin-images .block__image img,
.block--editor-twin-images .block__image img {
  width: 100%;
  height: auto;
}
.block--twin-images {
  padding-right: 24px;
  padding-left: 24px;
}
@media screen and (max-width: 62.4375em) {
  .block--twin-images {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--twin-images .block__image {
    margin-left: 0;
    margin-right: 0;
  }
}

.block--editor-twin-callouts.block--wide .wrap {
  padding: 0;
  margin: 0;
  position: relative;
}
.block--editor-twin-callouts.block--wide .wrap {
  max-width: none;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts.block--wide .wrap {
    padding: 0 16px;
  }
}
.block--editor-twin-callouts.block--wide .block__grid {
  gap: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts.block--wide .block__grid {
    gap: 60px;
  }
}
.block--editor-twin-callouts.block--wide .block__image {
  overflow: hidden;
  margin-bottom: 0;
}
.block--editor-twin-callouts.block--wide .block__image:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--editor-twin-callouts.block--wide .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts.block--wide .block__image {
    overflow: hidden;
    margin-bottom: 24px;
  }
  .block--editor-twin-callouts.block--wide .block__image:before {
    float: left;
    content: "";
    padding-bottom: 75%;
    margin-bottom: 0px;
  }
  .block--editor-twin-callouts.block--wide .block__image:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--editor-twin-callouts.block--wide .block__content {
  position: absolute;
  right: 24px;
  left: 24px;
  bottom: 48px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts.block--wide .block__content {
    position: relative;
    left: unset;
    right: unset;
    bottom: unset;
  }
}
.block--editor-twin-callouts.block--wide .block__subtitle {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.1052631579;
  color: #FFFFFF;
  margin-bottom: 26px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts.block--wide .block__subtitle {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 15px;
  }
}
.block--editor-twin-callouts .block__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts .block__grid {
    grid-template-columns: 1fr;
    gap: 60px;
  }
}
.block--editor-twin-callouts .block__grid-item {
  position: relative;
}
.block--editor-twin-callouts .block__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
  margin-bottom: 20px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-twin-callouts .block__title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2666666667;
  }
}
.block--editor-twin-callouts .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .block--editor-twin-callouts .block__title {
    margin-bottom: 10px;
  }
  .block--editor-twin-callouts .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--editor-twin-callouts .block__image {
  margin-bottom: 20px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts .block__image {
    margin-right: -16px;
    margin-left: -16px;
  }
}
.block--editor-twin-callouts .block__image img {
  width: 100%;
  height: auto;
}
.block--editor-twin-callouts .block__grid-item > .block__image:last-child {
  margin-bottom: 0;
}
.block--editor-twin-callouts a:last-child .block__image {
  margin-bottom: 0;
}
.block--editor-twin-callouts .block__video {
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
  pointer-events: none;
}
.block--editor-twin-callouts .block__video:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--editor-twin-callouts .block__video:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--editor-twin-callouts .block__video:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts .block__video {
    margin-right: -16px;
    margin-left: -16px;
  }
}
.block--editor-twin-callouts .block__video iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.block--editor-twin-callouts .block__video img {
  width: 100%;
  height: auto;
}
.block--editor-twin-callouts .block__subtitle {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  margin-bottom: 16px;
}
.block--editor-twin-callouts .block__subtitle:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-twin-callouts .block__subtitle {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
.block--editor-twin-callouts .block__text {
  margin-bottom: 20px;
  margin-top: 24px;
}
.block--editor-twin-callouts .block__text:last-child {
  margin-bottom: 0;
}
.block--editor-twin-callouts .block__text:last-child {
  margin-top: 0;
}

@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .wrap,
  .block--triple-callouts .wrap {
    padding: 0 10px;
  }
}
.block--editor-triple-callouts .block__title,
.block--triple-callouts .block__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
  margin-bottom: 26px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .block__title,
  .block--triple-callouts .block__title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2666666667;
  }
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .block__title,
  .block--triple-callouts .block__title {
    margin-bottom: 10px;
  }
}
.block--editor-triple-callouts .block__image,
.block--triple-callouts .block__image {
  margin-bottom: 26px;
}
.block--editor-triple-callouts .block__image:last-child,
.block--triple-callouts .block__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .block__image,
  .block--triple-callouts .block__image {
    margin-right: -10px;
    margin-left: -10px;
  }
}
.block--editor-triple-callouts .block__image img,
.block--triple-callouts .block__image img {
  width: 100%;
  height: auto;
}
.block--editor-triple-callouts .block__subtitle,
.block--triple-callouts .block__subtitle {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  margin-bottom: 26px;
}
.block--editor-triple-callouts .block__text,
.block--triple-callouts .block__text {
  margin-bottom: 26px;
}
.block--editor-triple-callouts .block__text:last-child,
.block--triple-callouts .block__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .block__text,
  .block--triple-callouts .block__text {
    padding: 0;
  }
}
.block--editor-triple-callouts .block__text ul li:before,
.block--triple-callouts .block__text ul li:before {
  transform: translateX(-20px);
  left: auto;
}
.block--editor-triple-callouts .text-center .block__text,
.block--triple-callouts .text-center .block__text {
  padding: 0 40px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .block__image:last-child,
  .block--editor-triple-callouts .block__text:last-child,
  .block--triple-callouts .block__image:last-child,
  .block--triple-callouts .block__text:last-child {
    margin-bottom: -10px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .grid > *,
  .block--triple-callouts .grid > * {
    padding-bottom: 48px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .grid > *:last-child, .block--editor-triple-callouts .grid > *:nth-last-child(2),
  .block--triple-callouts .grid > *:last-child,
  .block--triple-callouts .grid > *:nth-last-child(2) {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .grid > *:nth-last-child(2),
  .block--triple-callouts .grid > *:nth-last-child(2) {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--editor-triple-callouts .grid > *:last-child,
  .block--triple-callouts .grid > *:last-child {
    padding-bottom: 0;
  }
}

.block--quad-callouts .band__title,
.block--editor-quad-callouts .band__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
  margin-bottom: 20px;
}
.block--quad-callouts .block__title,
.block--editor-quad-callouts .block__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
  margin-bottom: 20px;
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__title,
  .block--editor-quad-callouts .block__title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2666666667;
  }
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__title,
  .block--editor-quad-callouts .block__title {
    margin-bottom: 10px;
  }
}
.block--quad-callouts .block__image,
.block--editor-quad-callouts .block__image {
  margin-bottom: 20px;
}
.block--quad-callouts .block__image:last-child,
.block--editor-quad-callouts .block__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 34.375em) {
  .block--quad-callouts .block__image,
  .block--editor-quad-callouts .block__image {
    margin-right: -16px;
    margin-left: -16px;
  }
}
.block--quad-callouts .block__image img,
.block--editor-quad-callouts .block__image img {
  width: 100%;
  height: auto;
}
.block--quad-callouts .block__subtitle,
.block--editor-quad-callouts .block__subtitle {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  margin-bottom: 10px;
}
.block--quad-callouts .block__intro,
.block--editor-quad-callouts .block__intro {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 10px;
  max-width: 795px;
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__intro,
  .block--editor-quad-callouts .block__intro {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
}
.block--quad-callouts .block__intro:last-child,
.block--editor-quad-callouts .block__intro:last-child {
  margin-bottom: 0;
}
.block--quad-callouts .block__text,
.block--editor-quad-callouts .block__text {
  margin-bottom: 10px;
}
.block--quad-callouts .block__text:last-child,
.block--editor-quad-callouts .block__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__image:last-child,
  .block--quad-callouts .block__text:last-child,
  .block--editor-quad-callouts .block__image:last-child,
  .block--editor-quad-callouts .block__text:last-child {
    margin-bottom: -10px;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--quad-callouts .grid > *,
  .block--editor-quad-callouts .grid > * {
    padding-bottom: 48px;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--quad-callouts .grid > *:last-child, .block--quad-callouts .grid > *:nth-last-child(2),
  .block--editor-quad-callouts .grid > *:last-child,
  .block--editor-quad-callouts .grid > *:nth-last-child(2) {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 34.375em) {
  .block--quad-callouts .grid > *:nth-last-child(2),
  .block--editor-quad-callouts .grid > *:nth-last-child(2) {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 34.375em) {
  .block--quad-callouts .grid > *:last-child,
  .block--editor-quad-callouts .grid > *:last-child {
    padding-bottom: 0;
  }
}
.block--quad-callouts .block__callout,
.block--editor-quad-callouts .block__callout {
  margin-right: 10px;
  width: 434px;
}
.block--quad-callouts .block__callout:last-child,
.block--editor-quad-callouts .block__callout:last-child {
  margin-right: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--quad-callouts .block__callout,
  .block--editor-quad-callouts .block__callout {
    width: 280px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__callout,
  .block--editor-quad-callouts .block__callout {
    flex: 0 0 auto;
    width: calc(50vw - 24px);
    margin-right: 16px;
  }
}
@media screen and (max-width: 34.375em) {
  .block--quad-callouts .block__callout,
  .block--editor-quad-callouts .block__callout {
    width: calc(90vw - 16px);
    margin-right: 10px;
  }
}
.block--quad-callouts .block__callout:last-child,
.block--editor-quad-callouts .block__callout:last-child {
  position: relative;
  margin-right: 10px;
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__callout:last-child,
  .block--editor-quad-callouts .block__callout:last-child {
    margin-right: 16px;
  }
}
.block--quad-callouts .block__callout:last-child:after,
.block--editor-quad-callouts .block__callout:last-child:after {
  content: "";
  display: block;
  position: absolute;
  right: -10px;
  width: 10px;
  height: 1px;
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__callout:last-child:after,
  .block--editor-quad-callouts .block__callout:last-child:after {
    right: -16px;
    width: 16px;
  }
}
.block--quad-callouts .block__callout .block__image,
.block--editor-quad-callouts .block__callout .block__image {
  width: 434px;
}
@media screen and (max-width: 62.4375em) {
  .block--quad-callouts .block__callout .block__image,
  .block--editor-quad-callouts .block__callout .block__image {
    width: 280px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--quad-callouts .block__callout .block__image,
  .block--editor-quad-callouts .block__callout .block__image {
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }
}

.block--editor-splash {
  position: relative;
}
.wrap .block--editor-splash .wrap {
  padding: 0;
}
.block--editor-splash .block__title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  margin-bottom: 20px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-splash .block__title {
    margin-bottom: 10px;
  }
}
.block--editor-splash .block__image {
  margin-bottom: 24px;
}
.block--editor-splash .block__image img {
  width: 100%;
  height: auto;
}
.block--editor-splash .block__video {
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
  pointer-events: none;
}
.block--editor-splash .block__video:before {
  float: left;
  content: "";
  padding-bottom: 80%;
  margin-bottom: 0px;
}
.block--editor-splash .block__video:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-splash .block__video {
    margin-right: -17px;
    margin-left: -17px;
  }
}
.block--editor-splash .block__video--square {
  overflow: hidden;
}
.block--editor-splash .block__video--square:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--editor-splash .block__video--square:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--editor-splash .block__video iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.block--editor-splash .block__video img {
  width: 100%;
  height: auto;
}
.block--editor-splash .l-one-half > .block__image:last-child,
.block--editor-splash .l-one-half .a:last-child .block__image {
  margin-bottom: 0;
}
.block--editor-splash .block__text {
  margin-bottom: 15px;
}
.block--editor-splash .block__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-splash .grid > :first-child {
    padding-bottom: 36px;
  }
}

.block--vimeo-video .band > div,
.block--editor-vimeo-video .band > div,
.block--editor-you-tube-video .band > div {
  position: relative;
}
.block--vimeo-video .block__video,
.block--editor-vimeo-video .block__video,
.block--editor-you-tube-video .block__video {
  overflow: hidden;
  position: relative;
}
.block--vimeo-video .block__video:before,
.block--editor-vimeo-video .block__video:before,
.block--editor-you-tube-video .block__video:before {
  float: left;
  content: "";
  padding-bottom: 56.25%;
  margin-bottom: 0px;
}
.block--vimeo-video .block__video:after,
.block--editor-vimeo-video .block__video:after,
.block--editor-you-tube-video .block__video:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--vimeo-video .block__video iframe,
.block--editor-vimeo-video .block__video iframe,
.block--editor-you-tube-video .block__video iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.block--vimeo-video .block__video img,
.block--editor-vimeo-video .block__video img,
.block--editor-you-tube-video .block__video img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .block__video--mobile + .block__video,
  .block--editor-vimeo-video .block__video--mobile + .block__video,
  .block--editor-you-tube-video .block__video--mobile + .block__video {
    display: none;
  }
}
.block--vimeo-video .block__video--mobile,
.block--editor-vimeo-video .block__video--mobile,
.block--editor-you-tube-video .block__video--mobile {
  display: none;
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .block__video--mobile,
  .block--editor-vimeo-video .block__video--mobile,
  .block--editor-you-tube-video .block__video--mobile {
    display: block;
    overflow: hidden;
    margin-left: -2px;
    margin-right: -2px;
  }
  .block--vimeo-video .block__video--mobile:before,
  .block--editor-vimeo-video .block__video--mobile:before,
  .block--editor-you-tube-video .block__video--mobile:before {
    float: left;
    content: "";
    padding-bottom: 150%;
    margin-bottom: 0px;
  }
  .block--vimeo-video .block__video--mobile:after,
  .block--editor-vimeo-video .block__video--mobile:after,
  .block--editor-you-tube-video .block__video--mobile:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--vimeo-video .block__play,
.block--editor-vimeo-video .block__play,
.block--editor-you-tube-video .block__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
.block--vimeo-video .block__play svg,
.block--editor-vimeo-video .block__play svg,
.block--editor-you-tube-video .block__play svg {
  display: block;
}
.block--vimeo-video .block__play span,
.block--editor-vimeo-video .block__play span,
.block--editor-you-tube-video .block__play span {
  margin-top: 8px;
  display: block;
  text-align: center;
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .block__play span,
  .block--editor-vimeo-video .block__play span,
  .block--editor-you-tube-video .block__play span {
    margin-top: 3px;
  }
}
.block--vimeo-video .block__video-preview,
.block--editor-vimeo-video .block__video-preview,
.block--editor-you-tube-video .block__video-preview {
  width: 100%;
  height: auto;
}
.block--vimeo-video .block__video-preview,
.block--vimeo-video .block__video-content,
.block--editor-vimeo-video .block__video-preview,
.block--editor-vimeo-video .block__video-content,
.block--editor-you-tube-video .block__video-preview,
.block--editor-you-tube-video .block__video-content {
  display: block;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.block--vimeo-video .block__video-content,
.block--editor-vimeo-video .block__video-content,
.block--editor-you-tube-video .block__video-content {
  top: auto;
  bottom: 50px;
  z-index: 10;
  left: 24px;
  color: #FFFFFF;
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .block__video-content,
  .block--editor-vimeo-video .block__video-content,
  .block--editor-you-tube-video .block__video-content {
    position: static;
    bottom: 0;
    margin: 15px 24px;
    color: inherit;
  }
}
.block--vimeo-video .wrap .block__video-content,
.block--editor-vimeo-video .wrap .block__video-content,
.block--editor-you-tube-video .wrap .block__video-content {
  left: 48px;
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .wrap .block__video-content,
  .block--editor-vimeo-video .wrap .block__video-content,
  .block--editor-you-tube-video .wrap .block__video-content {
    margin: 15px 0;
  }
}
.block--vimeo-video .block__title,
.block--editor-vimeo-video .block__title,
.block--editor-you-tube-video .block__title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.1666666667;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 10px;
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .block__title,
  .block--editor-vimeo-video .block__title,
  .block--editor-you-tube-video .block__title {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.1428571429;
  }
}
.block--vimeo-video .block__title:last-child,
.block--editor-vimeo-video .block__title:last-child,
.block--editor-you-tube-video .block__title:last-child {
  margin-bottom: 0;
}
.block--vimeo-video .block__intro,
.block--editor-vimeo-video .block__intro,
.block--editor-you-tube-video .block__intro {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 20px;
  max-width: 500px;
}
.block--vimeo-video .block__intro:last-child,
.block--editor-vimeo-video .block__intro:last-child,
.block--editor-you-tube-video .block__intro:last-child {
  margin-bottom: 0;
}
.block--vimeo-video .video--text-centred .block__video-content,
.block--editor-vimeo-video .video--text-centred .block__video-content,
.block--editor-you-tube-video .video--text-centred .block__video-content {
  top: 50%;
  left: 50%;
  bottom: unset;
  transform: translate(-50%, -160%);
  text-align: center;
}
.block--vimeo-video .video--text-centred .block__video-content .block__intro,
.block--editor-vimeo-video .video--text-centred .block__video-content .block__intro,
.block--editor-you-tube-video .video--text-centred .block__video-content .block__intro {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .video--text-centred .block__video-content,
  .block--editor-vimeo-video .video--text-centred .block__video-content,
  .block--editor-you-tube-video .video--text-centred .block__video-content {
    transform: unset;
    text-align: unset;
    max-width: unset;
  }
}
.block--vimeo-video .video--text-left-centred .block__video-content,
.block--editor-vimeo-video .video--text-left-centred .block__video-content,
.block--editor-you-tube-video .video--text-left-centred .block__video-content {
  top: 50%;
  bottom: unset;
  transform: translate(0%, -50%);
  max-width: 50%;
}
@media screen and (max-width: 62.4375em) {
  .block--vimeo-video .video--text-left-centred .block__video-content,
  .block--editor-vimeo-video .video--text-left-centred .block__video-content,
  .block--editor-you-tube-video .video--text-left-centred .block__video-content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .video--text-left-centred .block__video-content,
  .block--editor-vimeo-video .video--text-left-centred .block__video-content,
  .block--editor-you-tube-video .video--text-left-centred .block__video-content {
    transform: unset;
    max-width: unset;
  }
}
.block--vimeo-video .video--text-right-centred .block__video-content,
.block--editor-vimeo-video .video--text-right-centred .block__video-content,
.block--editor-you-tube-video .video--text-right-centred .block__video-content {
  top: 50%;
  left: unset;
  right: 48px;
  bottom: unset;
  max-width: 50%;
  transform: translate(0%, -50%);
}
@media screen and (max-width: 62.4375em) {
  .block--vimeo-video .video--text-right-centred .block__video-content,
  .block--editor-vimeo-video .video--text-right-centred .block__video-content,
  .block--editor-you-tube-video .video--text-right-centred .block__video-content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .video--text-right-centred .block__video-content,
  .block--editor-vimeo-video .video--text-right-centred .block__video-content,
  .block--editor-you-tube-video .video--text-right-centred .block__video-content {
    transform: unset;
    max-width: unset;
  }
}
.block--vimeo-video .video--text-centred-bottom .block__video-content,
.block--editor-vimeo-video .video--text-centred-bottom .block__video-content,
.block--editor-you-tube-video .video--text-centred-bottom .block__video-content {
  top: unset;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, 0%);
  text-align: center;
  max-width: 75%;
}
.block--vimeo-video .video--text-centred-bottom .block__video-content .block__intro,
.block--editor-vimeo-video .video--text-centred-bottom .block__video-content .block__intro,
.block--editor-you-tube-video .video--text-centred-bottom .block__video-content .block__intro {
  max-width: unset;
}
@media screen and (max-width: 62.4375em) {
  .block--vimeo-video .video--text-centred-bottom .block__video-content,
  .block--editor-vimeo-video .video--text-centred-bottom .block__video-content,
  .block--editor-you-tube-video .video--text-centred-bottom .block__video-content {
    max-width: 100%;
    left: unset;
    transform: translate(0%, 0%);
  }
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .video--text-centred-bottom .block__video-content,
  .block--editor-vimeo-video .video--text-centred-bottom .block__video-content,
  .block--editor-you-tube-video .video--text-centred-bottom .block__video-content {
    transform: unset;
    text-align: unset;
    max-width: unset;
  }
}
.block--vimeo-video .video--text-left-bottom .block__video-content,
.block--editor-vimeo-video .video--text-left-bottom .block__video-content,
.block--editor-you-tube-video .video--text-left-bottom .block__video-content {
  bottom: 50px;
  left: 48px;
  max-width: 50%;
}
@media screen and (max-width: 62.4375em) {
  .block--vimeo-video .video--text-left-bottom .block__video-content,
  .block--editor-vimeo-video .video--text-left-bottom .block__video-content,
  .block--editor-you-tube-video .video--text-left-bottom .block__video-content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .video--text-left-bottom .block__video-content,
  .block--editor-vimeo-video .video--text-left-bottom .block__video-content,
  .block--editor-you-tube-video .video--text-left-bottom .block__video-content {
    transform: unset;
    max-width: unset;
  }
}
.block--vimeo-video .video--text-right-bottom .block__video-content,
.block--editor-vimeo-video .video--text-right-bottom .block__video-content,
.block--editor-you-tube-video .video--text-right-bottom .block__video-content {
  left: unset;
  right: 48px;
  bottom: 50px;
  max-width: 50%;
}
@media screen and (max-width: 62.4375em) {
  .block--vimeo-video .video--text-right-bottom .block__video-content,
  .block--editor-vimeo-video .video--text-right-bottom .block__video-content,
  .block--editor-you-tube-video .video--text-right-bottom .block__video-content {
    max-width: 100%;
  }
}
@media screen and (max-width: 42.5em) {
  .block--vimeo-video .video--text-right-bottom .block__video-content,
  .block--editor-vimeo-video .video--text-right-bottom .block__video-content,
  .block--editor-you-tube-video .video--text-right-bottom .block__video-content {
    transform: unset;
    max-width: unset;
  }
}

.block--triple-images .block__images > div:nth-child(1) {
  margin-top: 50px;
}
@media screen and (max-width: 42.5em) {
  .block--triple-images .block__images > div:nth-child(1) {
    margin-top: 0;
  }
  .block--triple-images .block__images > div:nth-child(1) .block__image {
    display: inline-block;
    max-width: 323px;
  }
}
.block--triple-images .block__images > div:nth-child(2) {
  margin-top: 120px;
}
@media screen and (max-width: 42.5em) {
  .block--triple-images .block__images > div:nth-child(2) {
    margin-top: 0;
  }
  .block--triple-images .block__images > div:nth-child(2) .block__image {
    display: inline-block;
    max-width: 286px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--triple-images .block__images > div:nth-child(3) {
    margin-top: 0;
  }
  .block--triple-images .block__images > div:nth-child(3) .block__image {
    display: inline-block;
    max-width: 314px;
  }
}
.block--triple-images .block__image img,
.block--triple-images .block__image picture {
  width: 100%;
  height: auto;
}

.block--form .block__panel,
.block--editor-form .block__panel {
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 62.4375em) {
  .block--form .block__panel,
  .block--editor-form .block__panel {
    max-width: 840px;
    margin: 0 auto;
    padding: 0 16px;
  }
}
.block--form .form__message,
.block--editor-form .form__message {
  margin: 20px 0;
}

.block__panel {
  padding: 0 24px;
}
@media screen and (max-width: 62.4375em) {
  .block__panel {
    padding: 0 16px;
  }
}
.block__panel .block__interior-bookings-iframe {
  margin: 25px auto;
}

.stores-carousel__header {
  position: relative;
  margin: 0 24px;
}
@media screen and (max-width: 42.5em) {
  .stores-carousel__header {
    margin: 0 16px;
  }
}

.stores-carousel__controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 42.5em) {
  .stores-carousel__controls {
    display: none;
  }
}

.stores-carousel__left,
.stores-carousel__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: transparent;
}
.stores-carousel__left[disabled],
.stores-carousel__right[disabled] {
  opacity: 0.3;
  pointer-events: none;
}
.stores-carousel__left svg,
.stores-carousel__right svg {
  width: 6px;
  height: 10px;
}

.stores-carousel__container {
  margin-bottom: 20px;
  display: flex;
  overflow: auto;
  padding-top: 9px;
  padding-bottom: 20px;
  padding-left: 24px;
}
.stores-carousel__container:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .stores-carousel__container {
    margin-bottom: 10px;
    padding-left: 16px;
  }
  .stores-carousel__container:last-child {
    margin-bottom: 0;
  }
}

.stores-carousel__item {
  color: inherit;
  text-decoration: none;
  margin-right: 10px;
}
.stores-carousel__item:last-child {
  margin-right: 0;
}
@media screen and (max-width: 42.5em) {
  .stores-carousel__item:last-child {
    margin-right: 15px;
  }
}

.stores-carousel__item-image {
  width: 390px;
  height: auto;
}
@media screen and (max-width: 34.375em) {
  .stores-carousel__item-image {
    width: 280px;
    height: auto;
  }
}

.stores-carousel__item-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
  margin-top: 15px;
}

.carousel {
  max-width: 1800px;
  margin: 0 auto;
}

.carousel__header {
  position: relative;
  margin: 0 24px;
}
@media screen and (max-width: 42.5em) {
  .carousel__header {
    margin: 0 16px;
  }
}

.carousel__controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 42.5em) {
  .carousel__controls {
    display: none;
  }
}

.carousel__left,
.carousel__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: transparent;
}
.carousel__left[disabled],
.carousel__right[disabled] {
  opacity: 0.3;
  pointer-events: none;
}
.carousel__left svg,
.carousel__right svg {
  width: 6px;
  height: 10px;
}

.carousel__container {
  margin-bottom: 20px;
  display: flex;
  overflow: auto;
  padding-top: 9px;
  padding-bottom: 20px;
  padding-left: 24px;
}
.carousel__container:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .carousel__container {
    margin-bottom: 10px;
    padding-left: 16px;
  }
  .carousel__container:last-child {
    margin-bottom: 0;
  }
}

.carousel__container--centered {
  justify-content: center;
}

@media screen and (min-width: 0px) and (max-width: 458px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 458px) and (max-width: 916px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 916px) and (max-width: 1374px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 1374px) and (max-width: 1832px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 1832px) and (max-width: 2290px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 2290px) and (max-width: 2748px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 2748px) and (max-width: 3206px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 3206px) and (max-width: 3664px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 3664px) and (max-width: 4122px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: start;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
@media screen and (min-width: 4122px) and (max-width: 4580px) {
  .carousel__container:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(2)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(3)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(4)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(5)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(6)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(7)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(8)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(9)) {
    justify-content: center;
  }
  .carousel__container:has(> :last-child:nth-child(10)) {
    justify-content: start;
  }
}
.block--editor-newsletter-signup .block__controls {
  background-color: #F0EEEB;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-newsletter-signup .block__controls {
    padding: 30px 15px 50px;
  }
}
.block--editor-newsletter-signup .block__title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  margin-bottom: 10px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-newsletter-signup .block__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
.block--editor-newsletter-signup .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}
.block--editor-newsletter-signup .block__field {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-newsletter-signup .block__field {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
  }
}
.block--editor-newsletter-signup .block__input {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  margin-right: 24px;
  margin-bottom: 0;
  overflow: hidden;
  max-width: 420px;
  width: 100%;
}
@media screen and (max-width: 42.5em) {
  .block--editor-newsletter-signup .block__input {
    max-width: none;
    margin-bottom: 12px;
    margin-right: 0;
  }
}
.block--editor-newsletter-signup .block__input input {
  background: #FFFFFF;
}
.block--editor-newsletter-signup .block__image {
  overflow: hidden;
  position: relative;
}
.block--editor-newsletter-signup .block__image:before {
  float: left;
  content: "";
  padding-bottom: 60%;
  margin-bottom: 0px;
}
.block--editor-newsletter-signup .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--editor-newsletter-signup .block__image img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.block--editor-newsletter-signup .button {
  border: 1px solid #000000;
}

.testimonial {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.25;
  padding: 40px;
  background: #F0EEEB;
  flex: 1;
}
@media screen and (max-width: 42.5em) {
  .testimonial {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.2;
    padding: 25px;
  }
}

.testimonial__author {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  margin-top: 15px;
}

.block--editor-process .products-list__container {
  margin: 0 24px;
  padding-top: 0;
  padding-left: 0;
  gap: 12px;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-process .products-list__container {
    margin: 0 16px;
  }
}
.block--editor-process .products-list__controls {
  display: none;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-process .products-list__controls {
    display: flex;
  }
}
@media screen and (max-width: 42.5em) {
  .block--editor-process .products-list__controls {
    display: none;
  }
}
.block--editor-process .process-steps__step {
  flex: 1;
  background: #F0EEEB;
  padding: 30px 10px;
  text-align: center;
}
.block--editor-process .process-steps__step--white {
  background: #FFFFFF;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-process .process-steps__step {
    flex: 0 0 auto;
    width: 100%;
    max-width: 450px;
  }
}
.block--editor-process .process-step__step-number {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 1.1428571429;
  margin-bottom: 10px;
}
.block--editor-process .process-step__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 10px;
}
.block--editor-process .process-step__link {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

/**
 * Very sorry for this file! Specific design requirements.
 * Basically on the homepage want 60px hero to carousel title,
 * other pages make it have 100px from the block above to card images.
 */
@media screen and (max-width: 42.5em) {
  .block--product-carousel .band[data-no-title] .products-list__header,
  .block--editor-product-carousel .band[data-no-title] .products-list__header {
    display: none;
  }
}

.block--editor-product-carousel:first-child .band.band.band {
  margin-top: 50px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-product-carousel:first-child .band.band.band {
    margin-top: 54px;
  }
}
.block--editor-product-carousel .band.band.band {
  margin-top: 22px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-product-carousel .band.band.band {
    margin-top: 60px;
  }
}
.block--editor-product-carousel .band.band.band[data-no-title] {
  margin-top: 46px;
}
@media screen and (max-width: 42.5em) {
  .block--editor-product-carousel .band.band.band[data-no-title] {
    margin-top: 60px;
  }
}

.block:has(.band):not(:has(.band--flush)):not(:has(.band--panel-normal)):not(:has(.band--panel-adapt)) + .block--editor-product-carousel .band.band {
  margin-top: -2px;
}
@media screen and (max-width: 42.5em) {
  .block:has(.band):not(:has(.band--flush)):not(:has(.band--panel-normal)):not(:has(.band--panel-adapt)) + .block--editor-product-carousel .band.band[data-no-title] {
    margin-top: 60px;
  }
}

.block:has(.band--tallest) + .block--editor-product-carousel .band.band.band.band.band,
.block:has(.band--tallest-adapt) + .block--editor-product-carousel .band.band.band.band.band {
  margin-top: -78px;
}
.block:has(.band--tallest) + .block--editor-product-carousel .band.band.band.band.band[data-no-title],
.block:has(.band--tallest-adapt) + .block--editor-product-carousel .band.band.band.band.band[data-no-title] {
  margin-top: -54px;
}

@media screen and (max-width: 62.4375em) {
  .block:has(.band--tallest-adapt) + .block--editor-product-carousel .band.band.band.band.band {
    margin-top: -46px;
  }
  .block:has(.band--tallest-adapt) + .block--editor-product-carousel .band.band.band.band.band[data-no-title] {
    margin-top: 60px;
  }
}

.article__content .block--product-carousel .band.band {
  margin-top: -60px;
}
@media screen and (max-width: 42.5em) {
  .article__content .block--product-carousel .band.band {
    margin-top: -44px;
  }
}
.article__content .block--product-carousel .band[data-no-title] {
  margin-top: -34px;
}
@media screen and (max-width: 42.5em) {
  .article__content .block--product-carousel .band[data-no-title] {
    margin-top: 60px;
  }
}

.block--delivery-returns {
  margin-bottom: 40px;
}
.block--delivery-returns:first-of-type {
  margin-top: 40px;
}
.block--delivery-returns .block__title {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.2666666667;
}
@media screen and (max-width: 42.5em) {
  .block--delivery-returns .block__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}

.button--border-white, .block--featured-post .band--rust .button--border, .block--featured-post .band--mustard .button--border, .block--featured-post .band--sage .button--border, .block--featured-post .band--grey-blue .button--border {
  border: 1px solid #FFFFFF;
}
[data-background=dark] .button--border-white, [data-background=dark] .block--featured-post .band--rust .button--border, .block--featured-post .band--rust [data-background=dark] .button--border, [data-background=dark] .block--featured-post .band--mustard .button--border, .block--featured-post .band--mustard [data-background=dark] .button--border, [data-background=dark] .block--featured-post .band--sage .button--border, .block--featured-post .band--sage [data-background=dark] .button--border, [data-background=dark] .block--featured-post .band--grey-blue .button--border, .block--featured-post .band--grey-blue [data-background=dark] .button--border {
  border-color: #000000;
}
.button--border-white[disabled], .block--featured-post .band--rust [disabled].button--border, .block--featured-post .band--mustard [disabled].button--border, .block--featured-post .band--sage [disabled].button--border, .block--featured-post .band--grey-blue [disabled].button--border, .button--border-white.button--disabled, .block--featured-post .band--rust .button--disabled.button--border, .block--featured-post .band--mustard .button--disabled.button--border, .block--featured-post .band--sage .button--disabled.button--border, .block--featured-post .band--grey-blue .button--disabled.button--border {
  border-color: #8d8d8d;
}
a:focus:not(:hover) .button--border-white, a:focus:not(:hover) .block--featured-post .band--rust .button--border, .block--featured-post .band--rust a:focus:not(:hover) .button--border, a:focus:not(:hover) .block--featured-post .band--mustard .button--border, .block--featured-post .band--mustard a:focus:not(:hover) .button--border, a:focus:not(:hover) .block--featured-post .band--sage .button--border, .block--featured-post .band--sage a:focus:not(:hover) .button--border, a:focus:not(:hover) .block--featured-post .band--grey-blue .button--border, .block--featured-post .band--grey-blue a:focus:not(:hover) .button--border, .button--border-white:focus:not(:hover), .block--featured-post .band--rust .button--border:focus:not(:hover), .block--featured-post .band--mustard .button--border:focus:not(:hover), .block--featured-post .band--sage .button--border:focus:not(:hover), .block--featured-post .band--grey-blue .button--border:focus:not(:hover), .button--border-white.button--focus:not(:hover), .block--featured-post .band--rust .button--focus.button--border:not(:hover), .block--featured-post .band--mustard .button--focus.button--border:not(:hover), .block--featured-post .band--sage .button--focus.button--border:not(:hover), .block--featured-post .band--grey-blue .button--focus.button--border:not(:hover), input:focus:not(:hover) + .button--border-white.button--radio, .block--featured-post .band--rust input:focus:not(:hover) + .button--radio.button--border, .block--featured-post .band--mustard input:focus:not(:hover) + .button--radio.button--border, .block--featured-post .band--sage input:focus:not(:hover) + .button--radio.button--border, .block--featured-post .band--grey-blue input:focus:not(:hover) + .button--radio.button--border {
  border-color: transparent;
}

.button--white-hover, .block--featured-post .band--rust .button, .block--featured-post .band--mustard .button, .block--featured-post .band--sage .button, .block--featured-post .band--grey-blue .button {
  color: #FFFFFF;
  background: inherit;
}
a:hover .button--white-hover, a:hover .block--featured-post .band--rust .button, .block--featured-post .band--rust a:hover .button, a:hover .block--featured-post .band--mustard .button, .block--featured-post .band--mustard a:hover .button, a:hover .block--featured-post .band--sage .button, .block--featured-post .band--sage a:hover .button, a:hover .block--featured-post .band--grey-blue .button, .block--featured-post .band--grey-blue a:hover .button, .button--white-hover:hover, .block--featured-post .band--rust .button:hover, .block--featured-post .band--mustard .button:hover, .block--featured-post .band--sage .button:hover, .block--featured-post .band--grey-blue .button:hover, .button--white-hover.button--hover, .block--featured-post .band--rust .button--hover.button, .block--featured-post .band--mustard .button--hover.button, .block--featured-post .band--sage .button--hover.button, .block--featured-post .band--grey-blue .button--hover.button, a:active .button--white-hover, a:active .block--featured-post .band--rust .button, .block--featured-post .band--rust a:active .button, a:active .block--featured-post .band--mustard .button, .block--featured-post .band--mustard a:active .button, a:active .block--featured-post .band--sage .button, .block--featured-post .band--sage a:active .button, a:active .block--featured-post .band--grey-blue .button, .block--featured-post .band--grey-blue a:active .button, .button--white-hover:active, .block--featured-post .band--rust .button:active, .block--featured-post .band--mustard .button:active, .block--featured-post .band--sage .button:active, .block--featured-post .band--grey-blue .button:active {
  color: #000000;
  background-color: #FFFFFF;
}
.button--white-hover[disabled], .block--featured-post .band--rust [disabled].button, .block--featured-post .band--mustard [disabled].button, .block--featured-post .band--sage [disabled].button, .block--featured-post .band--grey-blue [disabled].button, .button--white-hover.button--disabled, .block--featured-post .band--rust .button--disabled.button, .block--featured-post .band--mustard .button--disabled.button, .block--featured-post .band--sage .button--disabled.button, .block--featured-post .band--grey-blue .button--disabled.button {
  color: #FFFFFF;
  background-color: #8d8d8d;
}
[data-background=dark] .button--white-hover[disabled], [data-background=dark] .block--featured-post .band--rust [disabled].button, .block--featured-post .band--rust [data-background=dark] [disabled].button, [data-background=dark] .block--featured-post .band--mustard [disabled].button, .block--featured-post .band--mustard [data-background=dark] [disabled].button, [data-background=dark] .block--featured-post .band--sage [disabled].button, .block--featured-post .band--sage [data-background=dark] [disabled].button, [data-background=dark] .block--featured-post .band--grey-blue [disabled].button, .block--featured-post .band--grey-blue [data-background=dark] [disabled].button, [data-background=dark] .button--white-hover.button--disabled, [data-background=dark] .block--featured-post .band--rust .button--disabled.button, .block--featured-post .band--rust [data-background=dark] .button--disabled.button, [data-background=dark] .block--featured-post .band--mustard .button--disabled.button, .block--featured-post .band--mustard [data-background=dark] .button--disabled.button, [data-background=dark] .block--featured-post .band--sage .button--disabled.button, .block--featured-post .band--sage [data-background=dark] .button--disabled.button, [data-background=dark] .block--featured-post .band--grey-blue .button--disabled.button, .block--featured-post .band--grey-blue [data-background=dark] .button--disabled.button {
  color: #8d8d8d;
  background-color: #000000;
}

.block--featured-post .band--grey {
  background-color: #E5E5E5;
}
.block--featured-post .band--l-grey {
  background-color: #E5E5E5;
}
@media screen and (max-width: 62.4375em) {
  .block--featured-post .band--l-grey {
    background-color: transparent;
  }
}
.block--featured-post .band--white {
  background-color: #FFFFFF;
}
.block--featured-post .band--grey-blue {
  background-color: #47535A;
}
.block--featured-post .band--grey-blue .block__title, .block--featured-post .band--grey-blue .block__text * {
  color: #FFFFFF;
}
.block--featured-post .band--grey-blue .block__category {
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
}
.block--featured-post .band--sage {
  background-color: #7E7760;
}
.block--featured-post .band--sage .block__title, .block--featured-post .band--sage .block__text * {
  color: #FFFFFF;
}
.block--featured-post .band--sage .block__category {
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
}
.block--featured-post .band--mustard {
  background-color: #93693D;
}
.block--featured-post .band--mustard .block__title, .block--featured-post .band--mustard .block__text * {
  color: #FFFFFF;
}
.block--featured-post .band--mustard .block__category {
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
}
.block--featured-post .band--rust {
  background-color: #80493D;
}
.block--featured-post .band--rust .block__title, .block--featured-post .band--rust .block__text * {
  color: #FFFFFF;
}
.block--featured-post .band--rust .block__category {
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
}
.block--featured-post .block__left {
  flex: 0 0 auto;
}
.block--featured-post .block__image {
  overflow: hidden;
}
.block--featured-post .block__image:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--featured-post .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--featured-post .block__image img {
  width: 100%;
  height: auto;
}
.block--featured-post .grid--reverse .block__right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 62.4375em) {
  .block--featured-post .grid--reverse .block__right {
    display: block;
  }
}
.block--featured-post .block__panel {
  width: 100%;
  max-width: 668px;
  padding: 64px 24px 24px;
  text-align: left;
  display: inline-block;
}
@media screen and (max-width: 62.4375em) {
  .block--featured-post .block__panel {
    display: block;
    margin: 0 auto;
    padding: 30px 16px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--featured-post .block__panel {
    padding-top: 16px;
    padding-bottom: 50px;
  }
}
.block--featured-post .block__title {
  font-family: Cardo, serif;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.0416666667;
  max-width: 620px;
  margin-bottom: 10px;
}
@media screen and (max-width: 62.4375em) {
  .block--featured-post .block__title {
    max-width: none;
  }
}
@media screen and (max-width: 42.5em) {
  .block--featured-post .block__title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2666666667;
  }
}
.block--featured-post .block__title:last-child {
  margin-bottom: 0;
}
.block--featured-post .block__title a {
  color: inherit;
  text-decoration: none;
}
.block--featured-post .block__title a:hover {
  text-decoration: underline;
}
.block--featured-post .block__text {
  margin-bottom: 20px;
}
.block--featured-post .block__text:last-child {
  margin-bottom: 0;
}
.block--featured-post .block__category {
  display: inline-block;
  border: solid 1px #000000;
  border-radius: 10px;
  padding: 6px 16px;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 25px;
}
.block--featured-post .block__category:hover {
  text-decoration: underline;
}
.block--featured-post.block--padded-compact .block__image {
  overflow: hidden;
}
.block--featured-post.block--padded-compact .block__image:before {
  float: left;
  content: "";
  padding-bottom: 25%;
  margin-bottom: -0.75;
}
.block--featured-post.block--padded-compact .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--featured-post.block--padded-compact .block__image img {
  margin-top: -1px;
  margin-bottom: -1px;
}
.block--featured-post.block--padded-compact .block__right {
  text-align: left;
  display: flex;
  justify-content: flex-start;
}
.block--featured-post.block--padded-compact .block__left {
  padding: 64px 24px;
}
@media screen and (max-width: 62.4375em) {
  .block--featured-post.block--padded-compact .block__left {
    padding: 0;
  }
}
.block--featured-post.block--padded-compact .block__panel {
  max-width: 840px;
  align-content: center;
}
@media screen and (max-width: 62.4375em) {
  .block--featured-post.block--padded-compact .block__panel {
    max-width: none;
    padding: 42px 16px 60px 16px;
  }
}
.block--featured-post.block--padded-compact .grid--reverse .block__right {
  justify-content: flex-end;
}
@media screen and (max-width: 62.4375em) {
  .block--featured-post.block--padded-compact .grid--reverse .block__panel {
    max-width: none;
    padding-left: 16px;
  }
}
.block--featured-post.block--padded-compact .block__title {
  font-family: Cardo, serif;
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.2;
  margin-bottom: 20px;
}
@media screen and (max-width: 42.5em) {
  .block--featured-post.block--padded-compact .block__title {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.0952380952;
  }
}
.block--featured-post.block--padded-compact .block__title:last-child {
  margin-bottom: 0;
}

.block--featured2-posts .band--grey {
  background-color: #E5E5E5;
}
.block--featured2-posts .band--l-grey {
  background-color: #E5E5E5;
}
@media screen and (max-width: 62.4375em) {
  .block--featured2-posts .band--l-grey {
    background-color: transparent;
  }
}
.block--featured2-posts .band--white {
  background-color: #FFFFFF;
}
.block--featured2-posts .band--grey-blue {
  background-color: #47535A;
}
.block--featured2-posts .band--grey-blue .featured-posts__subtitle, .block--featured2-posts .band--grey-blue .featured-posts__title, .block--featured2-posts .band--grey-blue .featured-posts__caption, .block--featured2-posts .band--grey-blue a {
  color: #FFFFFF !important;
}
.block--featured2-posts .band--sage {
  background-color: #7E7760;
}
.block--featured2-posts .band--sage .featured-posts__subtitle, .block--featured2-posts .band--sage .featured-posts__title, .block--featured2-posts .band--sage .featured-posts__caption, .block--featured2-posts .band--sage a {
  color: #FFFFFF !important;
}
.block--featured2-posts .band--mustard {
  background-color: #93693D;
}
.block--featured2-posts .band--mustard .featured-posts__subtitle, .block--featured2-posts .band--mustard .featured-posts__title, .block--featured2-posts .band--mustard .featured-posts__caption, .block--featured2-posts .band--mustard a {
  color: #FFFFFF !important;
}
.block--featured2-posts .band--rust {
  background-color: #80493D;
}
.block--featured2-posts .band--rust .featured-posts__subtitle, .block--featured2-posts .band--rust .featured-posts__title, .block--featured2-posts .band--rust .featured-posts__caption, .block--featured2-posts .band--rust a {
  color: #FFFFFF !important;
}
.block--featured2-posts .featured-posts__container {
  text-align: center;
  padding: 70px 16px;
}
@media screen and (max-width: 42.5em) {
  .block--featured2-posts .featured-posts__container {
    padding: 50px 16px 0 16px;
  }
}
.block--featured2-posts .featured-posts__subtitle {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 500;
  letter-spacing: 2px;
  color: #000000;
  margin-bottom: 5px;
}
.block--featured2-posts .featured-posts__title {
  font-family: Cardo, serif;
  font-size: 62px;
  font-size: 3.875rem;
  line-height: 1.4516129032;
  font-weight: 500;
  margin-bottom: 10px;
}
@media screen and (max-width: 42.5em) {
  .block--featured2-posts .featured-posts__title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 2.8125;
  }
}
.block--featured2-posts .featured-posts__images {
  display: flex;
  justify-content: center;
  gap: 16px;
}
@media screen and (max-width: 42.5em) {
  .block--featured2-posts .featured-posts__images {
    flex-direction: column;
  }
}
.block--featured2-posts .featured-posts__image-wrapper {
  text-align: left;
  max-width: 330px;
}
@media screen and (max-width: 42.5em) {
  .block--featured2-posts .featured-posts__image-wrapper {
    max-width: 100%;
    padding-bottom: 30px;
  }
}
.block--featured2-posts .featured-posts__image-wrapper a {
  text-decoration: none;
  color: #000000;
}
.block--featured2-posts .featured-posts__image-wrapper a:hover {
  text-decoration: underline;
}
.block--featured2-posts .block__image img {
  width: 100%;
  max-width: 330px;
  height: auto;
  object-fit: cover;
}
@media screen and (max-width: 42.5em) {
  .block--featured2-posts .block__image img {
    max-width: 100%;
  }
}
.block--featured2-posts .featured-posts__caption {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin-top: 10px;
  max-width: 400px;
}

.block:has(.band--panel-adapt):not(:has(.band--grey)) + .block .band--panel-adapt.band:not(.band--grey),
.block:has(.band--panel-adapt.band--grey) + .block .band--panel-adapt.band--grey,
.block:has(.band--panel-normal.band--grey) + .block .band--panel-normal.band--grey {
  padding-top: 0;
}

.block--editor-faq-accordion .block__title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
}
@media screen and (max-width: 62.4375em) {
  .block--editor-faq-accordion .block__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}