.block--richtext,
.block--editor-richtext,
.block--editor-rich-text-with-cta {
    .block__panel {
        max-width: 720px;
        margin-right: auto;
        margin-left: auto;

        @include breakpoint(tablet) {
            max-width: calc($site-max-width / 2);
            margin: 0 auto;
            padding: 0 $site-gutter--mobile;
        }
    }

    .block__panel--center {
        text-align: center;
    }

    .block__panel--right {
        text-align: right;
    }

        .block__title {
            @include responsive-h2;

            margin-bottom: 15px;

            @include breakpoint(phablet) {
                margin-bottom: 10px;
            }
        }

        .block__title--center {
            text-align: center;
        }

        .block__title--right {
            text-align: right;
        }

        .block__text {
            @extend .richtext;
            @extend .richtext--large;
            @extend .richtext--flush;

            @include font(16px, 24px);
        }

        .block__text--center {
            text-align: center;
        }

        .block__text--right {
            text-align: right;
        }

        .block__button {
            margin-top: 60px;
        }

        .block__button,
        .block__button--center {
            text-align: center;
        }

        .block__button--left {
            text-align: left;
        }

    .block__panel--stylised {
        .block__title {
            @include apply-map($f-title);
            @include font(38px, 48px);

            margin-bottom: 26px;

            @include breakpoint(tablet) {
                @include font(26px, 32px);
            }
        }

        .block__text {
            @extend .richtext;
            @include font(16px, 24px);

            max-width: 496px;
            margin: 0 auto;

            @include breakpoint(phablet) {
                max-width: none;
            }
        }

        &.block__panel--center {
            @include breakpoint(phablet) {
                text-align: left;
            }
        }

        &.block__panel--right {
            .block__text {
                margin-right: 0;
            }

        }

        &.block__panel--left {
            .block__text {
                margin-left: 0;
            }
        }
    }
}

.block--richtext {
    .block__title {
        @include breakpoint(tablet) {
            text-align: left;
        }
    }

    .block__title--center {
        @include breakpoint(tablet) {
            text-align: center;
        }
    }

    .block__title--right {
        @include breakpoint(tablet) {
            text-align: right;
        }
    }
}
