@import 'tools/mixins/_margin';

.carousel {
    max-width: 1800px;
    margin: 0 auto;
}

    .carousel__header {
        position: relative;
        margin: 0 $site-gutter;

        @include breakpoint(phablet) {
            margin: 0 $site-gutter--mobile;
        }
    }

        .carousel__controls {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @include breakpoint(phablet) {
                display: none;
            }
        }

            .carousel__left,
            .carousel__right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                background: transparent;

                &[disabled] {
                    opacity: 0.3;
                    pointer-events: none;
                }

                svg {
                    width: 6px;
                    height: 10px;
                }
            }

    .carousel__container {
        @include margin(bottom, 20px);

        display: flex;
        overflow: auto;
        padding-top: 9px;
        padding-bottom: 20px;
        padding-left: $site-gutter;

        @include breakpoint(phablet) {
            @include margin(bottom, 10px);

            padding-left: $site-gutter--mobile;
        }
    }

    .carousel__container--centered {
        justify-content: center;
    }

    $itemWidth: 434px + $site-gutter;
    $handleItems: 10;
    $prevSize: 0px;
    @for $i from 1 through $handleItems {
      @media screen and (min-width: $prevSize) and (max-width: $itemWidth * $i) {
        @for $ii from 1 through $handleItems {
          .carousel__container:has(> :last-child:nth-child(#{$ii})) {
            @if ($i > $ii and $itemWidth * $i) {
              justify-content: center;
            } @else {
              justify-content: start;
            }
          }
        }
      }
    
      $prevSize: $itemWidth * $i;
    }
