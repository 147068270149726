.block--quad-callouts,
.block--editor-quad-callouts {

    .band__title {
        font-family: Cardo, serif;
        line-height: 110%;
        text-transform: none;
        font-weight: 400;
        letter-spacing: normal;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 48px;
        font-size: 3rem;
        line-height: 1.0416666667;
        margin-bottom: 20px;
    }

    .block__title {
        @include h1;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
            margin-bottom: 10px;
        }
    }

    .block__image {
        @include margin(bottom, 20px);

        @include breakpoint(mobile) {
            margin-right: -$site-gutter--mobile;
            margin-left: -$site-gutter--mobile;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__subtitle {
        @include h2;
        margin-bottom: 10px;
    }

    .block__intro {
        @include page-intro-subtitle;
        @include margin(bottom, 10px);

        max-width: 795px;
    }

    .block__text {
        @extend .richtext;
        @extend .richtext--flush;
        @include margin(bottom, 10px);
    }

    .block__image,
    .block__text {
        &:last-child {
            @include breakpoint(phablet) {
                margin-bottom: -10px;
            }
        }
    }

    .grid > * {
        @include breakpoint(tablet) {
            padding-bottom: 48px;
        }

        // The last 2
        &:last-child,
        &:nth-last-child(2) {
            @include breakpoint(tablet) {
                padding-bottom: 0;
            }
        }

        &:nth-last-child(2) {
            @include breakpoint(mobile) {
                padding-bottom: 40px;
            }
        }

        &:last-child {
            @include breakpoint(mobile) {
                padding-bottom: 0;
            }
        }
    }

    // This is borrowed from _product.scss
    .block__callout {
        @include margin(right, 10px);

        width: 434px;

        @include breakpoint(tablet) {
            width: 280px;
        }

        @include breakpoint(phablet) {
            flex: 0 0 auto;
            width: calc(50vw - #{$productCarouselWidth});
            margin-right: $site-gutter--mobile;
        }

        @include breakpoint(mobile) {
            width: calc(90vw - #{$site-gutter--mobile});
            margin-right: 10px;
        }

        &:last-child {
            position: relative;
            margin-right: 10px;

            @include breakpoint(phablet) {
                margin-right: $site-gutter--mobile;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: -10px;
                width: 10px;
                height: 1px;

                @include breakpoint(phablet) {
                    right: -$site-gutter--mobile;
                    width: $site-gutter--mobile;
                }
            }
        }

        .block__image {
            width: 434px;

            @include breakpoint(tablet) {
                width: 280px;
            }

            @include breakpoint(phablet) {
                width: auto;
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}
