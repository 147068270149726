.block--form,
.block--editor-form {
    .block__panel {
        max-width: 720px;
        margin-right: auto;
        margin-left: auto;

        @include breakpoint(tablet) {
            max-width: calc($site-max-width / 2);
            margin: 0 auto;
            padding: 0 $site-gutter--mobile;
        }
    }

    .form__message {
        @extend .richtext;
        @extend .richtext--large;
        @extend .richtext--flush;

        margin: 20px 0;
    }
}
