@import 'objects/_wrap';

.block--editor-heading {

    .block__panel {
        padding: 0 $site-gutter;

        @include breakpoint(tablet) {
            padding: 0 $site-gutter--mobile;
        }
    }

        .block__title {
            @include responsive-h2;
            max-width: 1156px;

            @include margin('bottom', 15px);
        }

        .block__text {
            @extend .richtext;
            @extend .richtext--large;
            @extend .richtext--flush;

            @include font(16px, 24px);

            max-width: 1156px;
        }

        .block__button {
            margin-top: 18px;
        }
}
