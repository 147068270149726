@import 'tools/mixins/_allow-for-bars';

.anchor-menu {
    padding-top: 92px;
    padding-bottom: 33px;
}

.anchor-menu--sticky {
    position: fixed;
    padding: 0;
    top: 0;
    width: 100%;
    z-index: 900;
    background: $c-grey;

    @include allowForBars((
        default: $headerHeight,
    ));

    @include breakpoint(tablet) {
        position: sticky;
    }

    @include breakpoint(phablet) {
        .body--admin &,
        .body--debug &,
        .body--admin.body--debug & {
            top: $headerHeightMobile;
        }
    }
}

    .anchor-menu__items-track {
        display: inline-block;

        @include breakpoint(tablet) {
            .anchor-menu--sticky & {
                overflow-x: auto;
                display: block;

                margin: 0 (-$site-gutter--mobile);
            }

            .anchor-menu__items {
                margin: 0 $site-gutter--mobile;
            }
        }
    }

    .anchor-menu__title {
        font-family: $f-title-stack;
        @include lightondark();
        @include font(62px, 70px);
        letter-spacing: 0;
        line-height: 50px;

        .anchor-menu--sticky & {
            display: inline-block;
            @include font(17px, 22px);
            margin-bottom: 0;
            margin-right: 20px;

            @include breakpoint(tablet) {
                margin: 16px 0 8px;
            }
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .anchor-menu__items {
        margin-top: 20px;
        list-style: none;

        .anchor-menu--sticky &  {
            display: inline-block;
            margin-top: 0;
            padding: 16px 0;
        }

        @include breakpoint(tablet) {
            white-space: nowrap;
        }
    }

        .anchor-menu__item {
            position: relative;
            display: inline-block;
            vertical-align: top;

            margin: 0 8px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

            .anchor-menu__link {
                position: relative;
                color: inherit;
                text-decoration: none;

                @include rem(17px);
                letter-spacing: 0;
                line-height: 22px;

                .anchor-menu--sticky & {
                    @include rem(14px);
                    line-height: 18px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background: $c-black;
                    transition: width .3s ease;
                }

                &:hover {
                    text-decoration: none;
                    @media (hover: hover) {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }

            .anchor-menu__link--active {
                &::after {
                    background: $c-black;
                    width: 100%;
                }
            }
