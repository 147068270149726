@import 'tools/mixins/_margin';

.stores-carousel {}

    .stores-carousel__header {
        position: relative;
        margin: 0 $site-gutter;

        @include breakpoint(phablet) {
            margin: 0 $site-gutter--mobile;
        }
    }

        .stores-carousel__controls {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @include breakpoint(phablet) {
                display: none;
            }
        }

            .stores-carousel__left,
            .stores-carousel__right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                background: transparent;

                &[disabled] {
                    opacity: 0.3;
                    pointer-events: none;
                }

                svg {
                    width: 6px;
                    height: 10px;
                }
            }

    .stores-carousel__container {
        @include margin(bottom, 20px);

        display: flex;
        overflow: auto;
        padding-top: 9px;
        padding-bottom: 20px;
        padding-left: $site-gutter;

        @include breakpoint(phablet) {
            @include margin(bottom, 10px);

            padding-left: $site-gutter--mobile;
        }
    }

        .stores-carousel__item {
            color: inherit;
            text-decoration: none;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;

                @include breakpoint(phablet) {
                    margin-right: 15px;
                }
            }
        }
            .stores-carousel__item-image {
                width: 390px;
                height: auto;

                @include breakpoint(mobile) {
                    width: 280px;
                    height: auto;
                }
            }

            .stores-carousel__item-title {
                @include h5;
                margin-top: 15px;
            }
