@use 'sass:math';

.block--editor-twin-callouts {
    &.block--wide {
        .wrap {
            padding: 0;
            margin: 0;
            position: relative;
        }

        .wrap {
            max-width: none;

            @include breakpoint(tablet) {
                padding: 0 $site-gutter--mobile;
            }
        }

        .block__grid {
            gap: 0;

            @include breakpoint(tablet) {
                gap: 60px;
            }
        }

        .block__image {
            @include aspect(1);

            margin-bottom: 0;

            @include breakpoint(tablet) {
                @include aspect(math.div(4, 3));

                margin-bottom: 24px;
            }
        }

        .block__content {
            position: absolute;
            right: 24px;
            left: 24px;
            bottom: 48px;

            @include breakpoint(tablet) {
                position: relative;
                left: unset;
                right: unset;
                bottom: unset;
            }
        }

        .block__subtitle {
            @include apply-map($f-title);
            @include font(38px, 42px);

            color: $c-white;
            margin-bottom: 26px;

            @include breakpoint(tablet) {
                @include font(30px, 36px);

                color: $c-black;
                margin-bottom: 15px;
            }
        }
    }

    .block__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            gap: 60px;
        }
    }

    .block__grid-item {
        position: relative;
    }

    .block__title {
        @include h1;

        @include margin('bottom', 20px);

        @include breakpoint(phablet) {
            @include margin('bottom', 10px);
        }
    }

    .block__image {
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            margin-right: -$site-gutter--mobile;
            margin-left: -$site-gutter--mobile;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__grid-item > .block__image:last-child {
        margin-bottom: 0;
    }

    a:last-child .block__image {
        margin-bottom: 0;
    }

    .block__video {
        @include aspect(1);
        position: relative;
        @include margin('bottom', 24px);
        pointer-events: none; // passthough so anchor works

        @include breakpoint(tablet) {
            margin-right: -$site-gutter--mobile;
            margin-left: -$site-gutter--mobile;
        }

        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__subtitle {
        @include h2;

        @include margin('bottom', 16px);

        @include breakpoint(tablet) {
            @include font(24px, 32px);
        }
    }

    .block__text {
        @extend .richtext;
        @extend .richtext--large;
        @extend .richtext--flush;
        @include margin(bottom, 20px);
        @include margin(top, 24px);
    }
}
