@use "sass:math";

.block--editor-splash {
    position: relative;

    .wrap {
        .wrap & {
            padding: 0;
        }
    }

    .block__title {
        @include h2;

        margin-bottom: 20px;

        @include breakpoint(phablet) {
            margin-bottom: 10px;
        }
    }

    .block__image {
        margin-bottom: 24px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__video {
        @include aspect(math.div(5,4));
        position: relative;
        margin-bottom: 24px;
        pointer-events: none; // passthough so anchor works

        @include breakpoint(tablet) {
            margin-right: -($site-gutter--mobile + 1);
            margin-left: -($site-gutter--mobile + 1);
        }

        &--square {
            @include aspect(1);
        }

        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .l-one-half {
        > .block__image:last-child,
        .a:last-child .block__image {
            margin-bottom: 0;
        }
    }

    .block__text {
        @extend .richtext;
        @extend .richtext--large;
        @extend .richtext--flush;
        @include margin(bottom, 15px);
    }

    .grid >:first-child  {

        @include breakpoint(tablet) {
            padding-bottom: 36px
        }
    }
}
