.block--editor-triple-callouts,
.block--triple-callouts {

    .wrap {
        @include breakpoint(phablet) {
            padding: 0 10px;
        }
    }

    .block__title {
        @include h1;
        margin-bottom: 26px;

        @include breakpoint(phablet) {
            margin-bottom: 10px;
        }
    }

    .block__image {
        @include margin(bottom, 26px);

        @include breakpoint(phablet) {
            margin-right: -10px;
            margin-left: -10px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__subtitle {
        @include h2;
        margin-bottom: 26px;
    }

    .block__text {
        @extend .richtext;
        @extend .richtext--flush;
        @include margin(bottom, 26px);

        @include breakpoint(phablet) {
            padding: 0;
        }

        ul {
            li:before {
                transform: translateX(-20px);
                left: auto;
            }
        }
    }

    .text-center .block__text {
        padding: 0 40px;
    }

    .block__image,
    .block__text {
        &:last-child {
            @include breakpoint(phablet) {
                margin-bottom: -10px;
            }
        }
    }

    .grid > * {
        @include breakpoint(phablet) {
            padding-bottom: 48px;
        }

        // The last 2
        &:last-child,
        &:nth-last-child(2) {
            @include breakpoint(phablet) {
                padding-bottom: 0;
            }
        }

        &:nth-last-child(2) {
            @include breakpoint(phablet) {
                padding-bottom: 40px;
            }
        }

        &:last-child {
            @include breakpoint(phablet) {
                padding-bottom: 0;
            }
        }
    }
}
