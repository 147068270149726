.block--triple-images {

    .block__images > div:nth-child(1) {
        margin-top: 50px;

        @include breakpoint(phablet) {
            margin-top: 0;

            .block__image {
                display: inline-block;
                max-width: 323px;
            }
        }
    }

    .block__images > div:nth-child(2) {
        margin-top: 120px;

        @include breakpoint(phablet) {
            margin-top: 0;

            .block__image {
                display: inline-block;
                max-width: 286px;
            }
        }
    }

    .block__images > div:nth-child(3) {
        @include breakpoint(phablet) {
            margin-top: 0;

            .block__image {
                display: inline-block;
                max-width: 314px;
            }
        }
    }

    .block__image {
        img,
        picture {
            width: 100%;
            height: auto;
        }
    }
}
