.block--editor-feature {

    .block__left {
        flex: 0 0 auto;
    }

    .block__image {
        @include aspect(1);

        img {
            width: 100%;
            height: auto;
        }
    }

    .grid--reverse .block__right {
        text-align: right;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(tablet) {
            display: block;
        }
    }

        .block__panel {
            width: 100%;
            max-width: 620px + ($site-gutter * 2);
            padding: 64px $site-gutter $site-gutter;
            text-align: left;
            display: inline-block;

            @include breakpoint(tablet) {
                display: block;
                margin: 0 auto;
                padding: 30px $site-gutter--mobile;
            }

            @include breakpoint(phablet) {
                padding-top: $site-gutter--mobile;
                padding-bottom: 50px;
            }
        }

            .block__title,
            .block__intro {
                max-width: 620px;

                @include breakpoint(tablet) {
                    max-width: none;
                }
            }

            .block__title {
                @include font(48px, 50px);

                @include breakpoint(phablet) {
                    @include font(30px, 38px);
                }

                @include margin(bottom, 10px);
            }

            .block__intro {
                @extend .richtext;
                @extend .richtext--flush;
                @include margin(bottom, 20px);
            }

            .block__text {
                @extend .richtext;
                @extend .richtext--flush;
                @include margin(bottom, 20px);
            }

    &.block--padded-compact {
        .block__image {
            @include aspect(4, 3);

            img {
                margin-top: -1px;
                margin-bottom: -1px;
            }
        }

        .block__right {
            text-align: left;
            display: flex;
            justify-content: flex-start;
        }

        .block__left {
            padding: 64px $site-gutter;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }

        .block__panel {
            max-width: calc($site-max-width / 2);
            align-content: center;

            @include breakpoint(tablet) {
                max-width: none;
                padding: 42px $site-gutter--mobile 60px $site-gutter--mobile;
            }
        }

        .grid--reverse {
            .block__right {
                justify-content: flex-end;
            }

            .block__panel {
                @include breakpoint(tablet) {
                    max-width: none;
                    padding-left: $site-gutter--mobile;
                }
            }
        }

        .block__title {
            @include font(60px, 72px);

            @include breakpoint(phablet) {
                @include font(42px, 46px);
            }

            @include margin(bottom, 20px);
            font-family: $f-title-stack;
        }
    }
}
