@use "sass:math";

.block--editor-newsletter-signup {

    .block__controls {
        background-color: $c-grey;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(tablet) {
            padding: 30px 15px 50px;
        }
    }

        .block__title {
            @include responsive-h2;
            margin-bottom: 10px;
        }

        .block__text {
            @extend .richtext;
            @extend .richtext--large;
            @extend .richtext--flush;

            @include font(16px, 24px);

            margin-bottom: 20px;
        }

        .block__field {
            display: flex;
            align-items: center;
            padding-bottom: 10px;

            @include breakpoint(phablet) {
                flex-direction: column;
                align-items: flex-start;
                border-bottom: none;
            }
        }

            .block__input {
                @include h6;
                margin-right: 24px;
                margin-bottom: 0;
                overflow: hidden;

                max-width: 420px;
                width: 100%;

                @include breakpoint(phablet) {
                    max-width: none;
                    margin-bottom: 12px;
                    margin-right: 0;
                }


                input {
                    background: $c-white;
                }
            }

    .block__image {
        @include aspect(math.div(5, 3));

        position: relative;

        img {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }

    .button {
        border: 1px solid $c-black;
    }
}
