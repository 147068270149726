.testimonial {
    @include font(24px, 30px);
    padding: 40px;
    background: $c-grey;
    flex: 1;

    @include breakpoint(phablet) {
        @include font(20px, 24px);
        padding: 25px;
    }
}

    .testimonial__author {
        @include font(16px, 24px);
        display: block;
        margin-top: 15px;
    }
