/**
 * Very sorry for this file! Specific design requirements.
 * Basically on the homepage want 60px hero to carousel title,
 * other pages make it have 100px from the block above to card images.
 */
.block--product-carousel,
.block--editor-product-carousel {
    .band[data-no-title] {
        .products-list__header {
            @include breakpoint(phablet) {
                display: none;
            }
        }
    }
}

.block--editor-product-carousel {
    &:first-child {
        .band.band.band {
            margin-top: 50px;

            @include breakpoint(phablet) {
                margin-top: 54px;
            }
        }
    }

    .band.band.band {
        margin-top: 22px;

        @include breakpoint(phablet) {
            margin-top: $band-tallest-mobile-adapt;
        }

        &[data-no-title] {
            margin-top: 46px;

            @include breakpoint(phablet) {
                margin-top: $band-tallest-mobile-adapt;
            }
        }
    }
}

.block:has(.band):not(:has(.band--flush)):not(:has(.band--panel-normal)):not(:has(.band--panel-adapt)) + .block--editor-product-carousel .band.band {
    margin-top: -2px;

    &[data-no-title] {
        @include breakpoint(phablet) {
            margin-top: $band-tallest-mobile-adapt;
        }
    }
}

.block:has(.band--tallest) + .block--editor-product-carousel .band.band.band.band.band,
.block:has(.band--tallest-adapt) + .block--editor-product-carousel .band.band.band.band.band {
    margin-top: -78px;

    &[data-no-title] {
        margin-top: -54px;
    }
}

.block:has(.band--tallest-adapt) + .block--editor-product-carousel .band.band.band.band.band {
    @include breakpoint(tablet) {
        margin-top: -46px;

        &[data-no-title] {
            margin-top: 60px;
        }
    }
}

// Blocks in blog posts always have a margin of 80 even if we wanted flush like the banner
.article__content .block--product-carousel {
    .band.band {
        margin-top: -60px;

        @include breakpoint(phablet) {
            margin-top: -44px;
        }
    }

    .band[data-no-title] {
        margin-top: -34px;

        @include breakpoint(phablet) {
            margin-top: $band-tallest-mobile-adapt;
        }
    }
}
