@use 'sass:math';

.block--editor-process {

    .products-list__container {
        margin: 0 $site-gutter;
        padding-top: 0;
        padding-left: 0;
        gap: math.div($site-gutter, 2);

        @include breakpoint(tablet) {
            margin: 0 $site-gutter--mobile;
        }
    }

    .products-list__controls {
        display: none;

        @include breakpoint(tablet) {
            display: flex;
        }

        @include breakpoint(phablet) {
            display: none;
        }
    }

    .process-steps__step {
        flex: 1;
        background: $c-grey;

        padding: 30px 10px;
        text-align: center;

        &--white {
            background: $c-white;
        }

        @include breakpoint(tablet) {
            flex: 0 0 auto;
            width: 100%;
            max-width: 450px;
        }
    }

        .process-step__step-number {
            @include apply-map($f-title);
            @include font(42px, 48px);
            margin-bottom: 10px;
        }

        .process-step__title {
            @include font(18px, 24px);
            margin-bottom: 10px;
        }

        .process-step__link {
            text-decoration: none;
            color: inherit;
            font-weight: 600;
        }
}
