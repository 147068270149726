@use "sass:math";

.block--image,
.block--editor-single-image {

    .image-right,
    .image-left {
        padding: 0 47px; // Design want different gutter if side by side

        @include breakpoint(tablet) {
            padding: 0 $site-gutter--mobile;
        }

        .grid  {
            margin-left: -47px;

            @include breakpoint(tablet) {
                margin-left: -($grid-gutter-h);
            }

            > * {
                padding-left: 47px;
                margin-bottom: 0;

                @include breakpoint(tablet) {
                    margin-bottom: 16px;
                    padding-left: $grid-gutter-h;
                }
            }
        }
    }

    .image-top-compact,
    .image-left,
    .image-right {
        max-width: 1172px;
    }

    .image-top-compact .grid > * {
        max-width: 448px;

        @include breakpoint(tablet) {
            max-width: none;
        }
    }

    .text-center,
    .text-right {
        @include breakpoint(tablet) {
            text-align: left;
        }
    }

    .block__title {
        @include font(32px, 32px);
        font-style: normal;
        margin-bottom: 22px;

        @include breakpoint(tablet) {
            @include font(24px, 32px);
            margin-bottom: 10px;
        }
    }

    .block__image {
        @include aspect(math.div(5, 3));

        @include breakpoint(tablet) {
            margin-left: auto;
            margin-right: auto;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__text {
        @extend .richtext;
        @include font(16px, 24px);
        @extend .richtext--flush;

        ul li {
            @include margin(bottom, 10px);
        }

        @include breakpoint(tablet) {
            @include font(14px, 22px);
        }
    }

    .block__image,
    .block__text {

        &:last-child {
            @include breakpoint(phablet) {
                margin-bottom: -10px;
            }
        }
    }

    .block__button {
        margin-top: 18px;
    }

    .image-top,
    .image-top-compact,
    .image-top-tall {
        .block__title {
            font-family: $f-title-stack;
            @include font(38px, 48px);

            @include breakpoint(tablet) {
                margin-bottom: 18px;
                @include font(26px, 32px);
            }
        }

        .block__image {
            @include margin(bottom, 30px);
        }
    }

    .image-left,
    .image-right {
        .block__image {
            @include aspect(math.div(4, 3));
        }
    }

    .image-top-tall {
        .block__image {
            @include aspect(math.div(9, 7));
        }
    }
}
