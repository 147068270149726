// Base
@import 'base/required';

.section-blocks {
    &:last-child {
        .block:last-child .band {
            @media screen and (min-width: calc(map-get(map-get($media-queries, 'phablet'), 'size') - 1) + 'px') {
                margin-bottom: 0;
            }
        }
    }
}

// Components
@import 'components/richtext';
@import 'components/hero';
@import 'components/anchor-menu';
@import 'components/blocks/banner';
@import 'components/blocks/feature';
@import 'components/blocks/heading';
@import 'components/blocks/richtext';
@import 'components/blocks/quote';
@import 'components/blocks/single-image';
@import 'components/blocks/twin-images';
@import 'components/blocks/twin-callouts';
@import 'components/blocks/triple-callouts';
@import 'components/blocks/quad-callouts';
@import 'components/blocks/splash';
@import 'components/blocks/video';
@import 'components/blocks/triple-images';
@import 'components/blocks/form';
@import 'components/blocks/interior-booking';
@import 'components/stores-carousel';
@import 'components/carousel';
@import 'components/blocks/newsletter-signup';
@import 'components/blocks/testimonials';
@import 'components/blocks/process';
@import 'components/blocks/product-carousel';

@import 'content/resources/scss/blocks';

.block:has(.band--panel-adapt):not(:has(.band--grey)) + .block .band--panel-adapt.band:not(.band--grey),
.block:has(.band--panel-adapt.band--grey) + .block .band--panel-adapt.band--grey,
.block:has(.band--panel-normal.band--grey) + .block .band--panel-normal.band--grey {
    padding-top: 0;
}

.block--editor-faq-accordion {
    .block__title {
        @include responsive-h2;
    }
}
